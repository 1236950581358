import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {requestGetAuthenticationData, requestGetAuthenticationSSOData, resetAuthenticationData, setRememberMe, setEulaHaveBeenAccepted} from "../../../actions";
import md5 from "md5";
import Cookies from "universal-cookie";
import {Typography, Button, TextField, Grid, Container, Checkbox, FormControlLabel, withStyles, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog, Paper} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Alert } from "@material-ui/lab";
import {PasswordLost} from "../../../api/api";
import Formulaire from "../../Formulaire/Formulaire";
import { faUndoAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loader } from "semantic-ui-react";
import 'semantic-ui-css/semantic.min.css'
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import "../../Formulaire/Formulaire.css"

export const cookies = new Cookies();

class MobinauteIdentificationFormulaire extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            login: "",
            password: "",
            success: this.props.dataAuth ? this.props.dataAuth : "",
            inscription: this.props.inscription,
            passwordlost: this.props.passwordlost,
            emailPasswordLost: "",
            loader:false,
            blur : 1,
            pass: false,
            checked: false,
            password_lost_error: false,
            password_lost_success: false,
            clicked: !!this.props.dataAuth,
            eulaHaveBeenAccepted: false
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.showSignUp = this.showSignUp.bind(this);
        this.showPasswordLost = this.showPasswordLost.bind(this);
        this.showLogin = this.showLogin.bind(this);
        this.sendPasswordLost = this.sendPasswordLost.bind(this);
    }


    componentDidMount() {
        let remember_me = parseInt(this.props.application.getApp.authentification_memory) === 1;
        this.setState({checked:remember_me})
        this.props.setRememberMe(remember_me)

        let eula_required = parseInt(this.props.application.getApp.eula_required) === 1;
        this.setState({eulaHaveBeenAccepted:eula_required})
        this.props.setEulaHaveBeenAccepted(remember_me)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.dataAuth.success !== prevProps.dataAuth.success){
            if(!this.props.dataAuth.success){
                this.setState({ success: this.props.dataAuth, pass: true});
            }
        } else if (this.state.pass){
            if (this.state.loader === true && !this.state.success.success){
                this.setState({loader: false, blur: 1})
                this.setState({ success: this.props.dataAuth});
            }
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const check = target.checked; //pour case à cocher

        if(this.state.clicked){
            this.props.resetAuthenticationData();
        }

        this.setState({success: "", clicked:false, pass:false})

        if(name === "checked"){
            this.props.setRememberMe(check)
        } else {
            this.props.setRememberMe(this.state.checked)
        }

        if(name === "eulaHaveBeenAccepted"){
            this.props.setEulaHaveBeenAccepted(check)
        } else {
            this.props.setEulaHaveBeenAccepted(this.state.eulaHaveBeenAccepted)
        }

        if (parseInt(this.props.application.getApp.authentification_memory) === 0){
            this.props.setRememberMe(false)
        }

        if (parseInt(this.props.application.getApp.eula_required) === 0){
            this.props.setEulaHaveBeenAccepted(false)
        }

        if (target.type === 'checkbox'){
            this.setState({
                [name]: check
            })
        } else {
            this.setState({
                [name]: value,
            });
        }
    }

    handleClick(e) {
        e.preventDefault();

        if (Object.keys(this.state.login).length === 0 || Object.keys(this.state.password).length === 0) {
            this.setState({success: {error: "Veuillez ne pas laisser de champs vides", error_code: 1201}, clicked:true})
        } else {
            if(!this.state.eulaHaveBeenAccepted && this.props.application.getApp.eula_required === 1){
                this.setState({success: {error: "Veuillez accepter les conditions", error_code: 1201}, clicked:true})
            }
            else {
                this.setState({success: "",loader : true, blur : 0.2, clicked:true})

                var pass = this.state.password
                if (parseInt(this.props.application.getApp.hash_pass_serverside) === 0){
                    pass = md5(this.state.password)
                }

                this.props.requestGetAuthenticationData({login: this.state.login, password: pass, eulaHaveBeenAccepted: this.state.eulaHaveBeenAccepted, inApp: true, pageId: this.props.application.pageId});
            }
        }
    }

    //Affichage du menu SignUp
    showSignUp(e) {
        e.preventDefault();

        this.setState({inscription: true, passwordlost: false});
    }

    //Affichage du menu Mot de passe Perdu
    showPasswordLost(e) {
        e.preventDefault();
        this.setState({ passwordlost: true });
    }

    //Affichage du menu Login
    showLogin() {
        this.setState({ inscription: false, passwordlost: false });
    }

    //Appel API de changement de Mot de passe Perdu
    sendPasswordLost(e) {
        e.preventDefault();
        PasswordLost(this.state.emailPasswordLost).then((res) => {
            if (res.success) {
                this.setState({
                    password_lost_success: true
                });
            } else {
                this.setState({
                    password_lost_error: true
                });
            }
        });
    }

    render() {

        let button_SSO;
        let dyn_string  = this.props.t;

        if(this.props.application.getApp.connectorAuthSSOs){
            if(this.props.application.getApp.authSSO && this.props.application.getApp.connectorAuthSSOs[0]){
                let dataSSO = this.props.application.getApp.connectorAuthSSOs[0];
                button_SSO = (
                    <a target={"_blank|_self|_parent|_top"} href={`${dataSSO.urlEnv}${dataSSO.endPointAuthorize}?acr_values=${dataSSO.acr_values}&response_type=code&client_id=${dataSSO.appId}&state=${dataSSO.state}&nonce=${Math.random(14)}&redirect_uri=${dataSSO.redirect}&scope=${dataSSO.scope}`}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={{background:this.props.styles.button.backgroundColor, marginTop:"10px",}}
                        >
                            {dyn_string('auth_btn_login_sso', {sso: dataSSO.title})}
                        </Button>
                    </a>
                )
            }
        }

        let errorMessage = (this.state.success.error_code === 1200 || this.state.success.error_code === 1201 || this.state.success.error_code === 1072 || this.state.success.error_code === 1073) && this.state.clicked ?
            (
                <Alert severity="error">{this.state.success.error} </Alert>
            ) :  ""

        //Mot de passe oublié
        let button_passwordLost;
        let button_passwordLost2;
        let margin;

        let password_lost_error = (
            <Dialog
                open={this.state.password_lost_error}
                onClose={() => this.setState({password_lost_error: false})}
                maxWidth={"sm"}
                fullWidth={true}
            >
                <DialogTitle>{dyn_string('form_alert_title_info_warning')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dyn_string('forgetpass_alert_msg_err_mail_not_found')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => this.setState({password_lost_error: false})}
                        style={{backgroundColor: this.props.styles.button.backgroundColor, borderColor: this.props.styles.button.backgroundColor, color: this.props.styles.button.color}}
                    >
                        {dyn_string('g_btn_ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        );

        let password_lost_success = (
            <Dialog
                open={this.state.password_lost_success}
                onClose={() => this.setState({password_lost_success: false})}
                maxWidth={"sm"}
                fullWidth={true}
            >
                <DialogTitle>{dyn_string('forgetpass_alert_msg_succ_sended')}</DialogTitle>
                <DialogActions>
                    <Button
                        onClick={() => this.setState({
                            password_lost_success: false,
                            inscription: false,
                            passwordlost: false
                        })}
                        style={{backgroundColor: this.props.styles.button.backgroundColor, borderColor: this.props.styles.button.backgroundColor, color: this.props.styles.button.color}}
                    >
                        {dyn_string('g_btn_ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        );

        if(parseInt(this.props.application.getApp.is_forget_password) === 1){
            button_passwordLost = (
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{
                        background: this.props.styles.button.backgroundColor,
                        marginTop: "10px",
                    }}
                    onClick={this.sendPasswordLost}
                >
                    {dyn_string('forgetpass_btn_send')}
                </Button>
            )

            //alignement des boutons si formualire d'inscription ou non
            if (parseInt(this.props.application.getApp.register_form) === 1){
                margin = "10px";
            } else {
                margin = "0px";
            }

            button_passwordLost2 = (
                <Grid item xs style={{ marginRight: margin, opacity: "0.8" }}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{
                            background: this.props.styles.button.backgroundColor,
                        }}
                        onClick={this.showPasswordLost}
                    >
                        {dyn_string('auth_label_forgotten_password')}
                    </Button>
                </Grid>
            )
        }
        //Inscription
        let textSubcribe;
        if (this.props.application.getApp.register_label === ""){
            textSubcribe = dyn_string('profile_title_signin');
        } else {
            textSubcribe = this.props.application.getApp.register_label;
        }

        let button_subscribe;
        if(parseInt(this.props.application.getApp.register_form) === 1){
            button_subscribe = (
                <Grid item xs>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{
                            background: this.props.styles.button.backgroundColor,
                            opacity: "0.8",
                        }}
                        onClick={this.showSignUp}
                    >
                        {textSubcribe}
                    </Button>
                </Grid>
            )
        }

        //Si Le menu MDP perdu est ouvert / Menu Inscription /  Menu Login
        if (this.state.passwordlost) {
            return (
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <div className="paper">
                        <p style={{textAlign: "center", marginTop: "20px"}}>
                            {dyn_string('forgetpass_label_instruction')}
                        </p>
                        <form className="form" noValidate>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="emailPasswordLost"
                                label={dyn_string('profile_label_mail')}
                                autoComplete="userName"
                                autoFocus
                                name="emailPasswordLost"
                                type="email"
                                placeholder={dyn_string('profile_placeholder_mail')}
                                value={this.state.emailPasswordLost}
                                onChange={this.handleChange}
                            />
                            {button_passwordLost}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                style={{
                                    background: this.props.styles.button.backgroundColor,
                                    marginTop: "10px",
                                    opacity: "0.8",
                                }}
                                onClick={this.showLogin}
                            >
                                {dyn_string('auth_title_login')}{" "}
                                <FontAwesomeIcon
                                    icon={faUndoAlt}
                                    style={{ marginLeft: "10px" }}
                                />
                            </Button>
                        </form>
                    </div>

                    {/* popup */}
                    {password_lost_error}
                    {password_lost_success}
                </Container>
            );
        }
        else if (this.state.inscription) {
            console.log(" %c ///////MENU_INSCRIPTION///////",  'background: #222; color: #bada55');

            return (
                <Container component="main">
                    <CssBaseline />
                    <div className="paper">
                        <Typography component="h1" variant="h5">
                            {dyn_string('profile_title_signin')}
                        </Typography>

                        <Formulaire
                            typeForm={"RegisterForm"}
                            Form={this.props.Form}
                            styles={this.props.styles}
                            getApp={this.props.application.getApp}
                            showLogin={this.showLogin}
                        >
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                style={{
                                    background: this.props.styles.button.backgroundColor,
                                    marginTop: "10px",
                                    marginBottom: "80px",
                                    opacity: "0.8",
                                }}
                                onClick={this.showLogin}
                            >
                                {dyn_string('auth_title_login')}{" "}
                                <FontAwesomeIcon
                                    icon={faUndoAlt}
                                    style={{ marginLeft: "10px" }}
                                />
                            </Button>
                        </Formulaire>
                    </div>
                </Container>
            );
        }
        else {
            console.log("%c ///////MENU_CONNEXION///////",  'background: #222; color: #bada55');

            const CustomCheckbox = withStyles({
                root: {
                    color: this.props.styles.button.backgroundColor,
                    "&$checked": {
                        color: this.props.styles.button.backgroundColor
                    }
                },
                checked: {}
            })((props) => <Checkbox color="default" {...props} />);

            let display_remember_me;

            if(parseInt(this.props.application.getApp.authentification_memory) === 1){
                display_remember_me = (
                    <FormControlLabel
                        control={
                            <CustomCheckbox
                                checked={this.state.checked}
                                onChange={this.handleChange}
                                name="checked"
                            />
                        }
                        label={dyn_string('auth_label_remember_me')}
                    />
                );
            } else {
                display_remember_me = "";
            }


            let display_eula;

            if(parseInt(this.props.application.getApp.eula_required) === 1){
                display_eula = (
                    <FormControlLabel
                        control={
                            <CustomCheckbox
                                checked={this.state.eulaHaveBeenAccepted}
                                onChange={this.handleChange}
                                name="eulaHaveBeenAccepted"
                            />
                        }
                        label={this.props.application.getApp.eula_required_text}
                    />
                );
            } else {
                display_eula = "";
            }

            let login_or_mail= (this.props.application.getApp.is_mail_login === "0") ? dyn_string('auth_placeholder_login') : dyn_string('auth_placeholder_mail');

            return (
                <>
                    <Loader active={this.state.loader} size='large' />
                    <Container component="main" maxWidth="xs" style={{opacity : this.state.blur}}>
                        <CssBaseline />
                        {errorMessage}
                        <Paper className="paper" style={{backgroundColor: "white", padding: "20px", marginTop: (this.state.width <= 800) ? "20px" : "100px", width:"100%", textAlign:"left", marginBottom:(this.state.width <= 800) ? "20px" : "200px"}}>
                            <Typography component="h1" variant="h5" style={{marginBottom : "10px"}}>

                            {dyn_string('auth_title_login')}
                            </Typography>

                            {this.props.application.getApp.identification_instruction}

                            <form className="form" noValidate>
                                {this.props.application.getApp.authShapper === "1" && (
                                    <>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="login"
                                            label={login_or_mail}
                                            autoComplete="userName"
                                            autoFocus
                                            name="login"
                                            type="email"
                                            placeholder={login_or_mail}
                                            value={this.state.value}
                                            onChange={this.handleChange}
                                        />
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            label={dyn_string('auth_placeholder_password')}
                                            id="password"
                                            autoComplete="current-password"
                                            name="password"
                                            value={this.state.password}
                                            onChange={this.handleChange}
                                            type="password"
                                            placeholder={dyn_string('auth_placeholder_password')}
                                        />

                                        <div>
                                            {display_eula}
                                        </div>

                                        <div>
                                            {display_remember_me}
                                        </div>

                                        <Button
                                            disabled={this.state.loader}
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            style={{
                                                background: this.props.styles.button.backgroundColor,
                                                marginBottom: "10px",
                                                marginTop: "10px",
                                            }}
                                            onClick={this.handleClick}
                                        >
                                            {this.state.loader? dyn_string('loading_label_authentication') : dyn_string('auth_btn_login')}
                                        </Button>
                                        <Grid container>
                                            {button_passwordLost2}
                                            {button_subscribe}
                                        </Grid>
                                    </>
                                )}

                                {button_SSO}
                            </form>
                        </Paper>
                    </Container>
                </>
            );
        }
    }
}


const mapStateToProps = (state) => ({
    data: state.content,
    dataAuth: state.contentauth,
    dataSSO : state.contentauthSso,
    styles: state.style,
    routes: state.routes,
    application: state.application
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            requestGetAuthenticationData,
            requestGetAuthenticationSSOData,
            resetAuthenticationData,
            setRememberMe,
            setEulaHaveBeenAccepted
        },
        dispatch
    );

export default compose( withTranslation(), connect(mapStateToProps, mapDispatchToProps))(MobinauteIdentificationFormulaire);
