import React from "react";
import "./Signature/sigCanvas.css";
import {
    Register,
    SetProfilForm,
    setResponseDynamicForm,
    getHistoricResponses,
    getHistoricVersions,
    uploadMediaStatic,
    tagTreatment,
    setMrktAnnounce
} from "../../api/api";
import Cookies from "universal-cookie";
import {bindActionCreators} from "redux";
import {addVariableData, requestGetPlanningsData} from "../../actions";
import {connect} from "react-redux";
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import {conditionalScript, constructTitleAndInstruction, constructFormData, initializeDefaultValue, REGEX_URL, constructVariableElement, constructEventElement, jsOutputTreatment, decodeHtmlAscii,constructDynamicFormData} from "./Formulaire.functions"
import FormulaireRegister from "./Formulaire.Register";
import FormulaireProfil from "./Formulaire.Profil";
import FormulaireButton from "./Formulaire.Button";
import FormulaireEventUpdate from "./Formulaire.Event.Update";
import FormulaireDynamic from "./FormulaireDynamic/Formulaire.Dynamic";
import {FormulaireTitle} from "./Formulaire.Title";
import {FormulaireInstruction} from "./Formulaire.Instruction";
import {ConfirmRegister, ConfirmProfilForm, ErrorForm, ConfirmDynamicForm} from "./Formulaire.popup"
import md5 from "md5";
import {choiceImage, isJsonParsable, pushHistory, dateToLocaleString} from "../../helpers/Tools"
import Container from "reactstrap/es/Container";
import {PopUpLoader, ShapperLoader} from "../../components/ShapperLoader/ShapperLoader";
import FlatList from "flatlist-react";

import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Grid,
    Paper,
    IconButton,
    Typography
} from "@material-ui/core";
import {ShapperButton} from "../../components/ShapperButton/ShapperButton";
import OpenItem from "../../helpers/OpenItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RestoreIcon from "@material-ui/icons/Restore";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import {store} from "../../store/store";

const cookies = new Cookies();

class Formulaire extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            login: "",
            firstname: "",
            lastname: "",
            Email: "",
            password: '',
            confirm_password: '',
            first_error: "",
            disabled : false,
            width: window.innerWidth,
            height: window.innerHeight,
            confirm_register: false,
            confirm_profil_form: false,
            confirm_dyn_form: false,
            error_form: false,
            loader: false,
            VariableContainer:{},
            formData : {},
            responses: {},
            idName: [],
            Files: {},
            isJsOutput: false,
            loading:true,
            list_form_response_identifier:[],
            list_form_response_versions:[]
        };

        // This binding is necessary to make `this` work in the callback
        this.handleChange = this.handleChange.bind(this);
        this.handleSignature = this.handleSignature.bind(this);
        this.clearStateSignature = this.clearStateSignature.bind(this);
        this.validate = this.validate.bind(this);
        this.onSubmitDynamicForm = this.onSubmitDynamicForm.bind(this);
        this.onSubmitRegister = this.onSubmitRegister.bind(this);
        this.onSubmitProfil = this.onSubmitProfil.bind(this);
        this.onSubmitCreateOrder = this.onSubmitCreateOrder.bind(this);
        this.setUpdateEvent = this.setUpdateEvent.bind(this);
        this.setAnnounce = this.setAnnounce.bind(this);
        this.resetState = this.resetState.bind(this);
        this.handleTagTreatment = this.handleTagTreatment.bind(this);
        this.resetState = this.resetState.bind(this);

    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    async componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);

        let form = this.props.Form;

        await this.loadDataInForm(form, null,true, true);

        if (this.props.typeForm === "ProfilForm" && this.props.profil.mobinaute){
            let mobinaute = this.props.profil.mobinaute
            await this.setState({
                login: mobinaute.login,
                firstname: mobinaute.firstname,
                lastname: mobinaute.lastname,
                Email: mobinaute.mail
            })
        }

        if(this.props.typeForm === "event_update" && this.props.formInfo && this.props.formInfo.event ){
            let event = constructEventElement(this.props.formInfo.event);

            await this.setState(event)
        }

        let formData_tmp = constructFormData(this.props.typeForm, this.props.profil, this.props.application, this.state, this.props.application.getApp, this.props.formInfo)

        await this.setState({
            formData: {...this.state.formData, ...formData_tmp},
            loading:false
        })
    }

    async showHistory(){
        //getHistoricResponses
        let formId = this.props.dataPage ? this.props.dataPage.formulaire_id : this.props.formInfo.formulaire_id;

        this.setState({
            list_form_response_identifier: []
        })

        await  getHistoricResponses({"formId":formId}).then(async (res) => {
            if (res.data.success) {
                this.setState({
                    list_form_response_identifier: res.data.result.responses
                })
            } else {
                console.log(res.error)
            }
        }).catch(async (e) => {
            console.log(e)
        });

    }

    async showVersions(formResponseIdentifier){
        //getHistoricResponses
        let formId = this.props.dataPage ? this.props.dataPage.formulaire_id : this.props.formInfo.formulaire_id;

        this.setState({
            list_form_response_versions: []
        })

        await  getHistoricVersions({"formId":formId, "formResponseIdentifier":formResponseIdentifier}).then(async (res) => {
            if (res.data.success) {
                this.setState({
                    list_form_response_versions: res.data.result.versions
                })
            } else {
                console.log(res.error)
            }
        }).catch(async (e) => {
            console.log(e)
        });

    }

    async loadResponseHistoricInForm(form, response){
        if(form && response){
            for (const input of form) {

                let initialisationData = {
                    "VariableContainer_tmp": {},
                    "input_value_tmp" : "",
                    "responses_tmp": null
                }

                for (const res of response) {

                    if(input.id == res.input_id) {

                        res.type = input.type;

                        initialisationData = initializeDefaultValue(res, null);

                        let VariableContainer_tmp = initialisationData.VariableContainer_tmp;
                        let input_value_tmp = initialisationData.input_value_tmp;
                        let responses_tmp = initialisationData.responses_tmp;

                        var inputValue = responses_tmp ? responses_tmp : input_value_tmp ? input_value_tmp : "";

                        var responseValue = "";
                        if((res.type === "list" || res.type === "List_Multi" || res.type === "list_btn" || res.type === "list_multi_btn") && Array.isArray(inputValue)){
                            responseValue = inputValue.join(",")
                        }
                        else {
                            responseValue = inputValue;
                        }
/*
                        console.log("res : ");
                        console.log(res.input_id + ":" + res.value_text);
                        console.log("inputValue : ");
                        console.log(inputValue);
                        console.log("responses_tmp : ");
                        console.log(responses_tmp);
                        console.log("responseValue : ");
                        console.log(responseValue);
                        console.log("this.state.idName : ");
                        console.log(this.state.idName);

 */

                        await this.setState({
                            responses: inputValue ? {...this.state.responses, [res.input_id]:responseValue} : {...this.state.responses},
                            VariableContainer: {...this.state.VariableContainer, ...VariableContainer_tmp},
                            idName: {...this.state.idName, ["v_"+res.input_id] : inputValue},
                            isJsOutput: this.state.isJsOutput ? true : res.type === "js_output",
                            ["v_"+res.id] : inputValue,
                            ["v_"+res.id+"error"] : "",
                            ["v_"+res.id+"_default"] : inputValue,
                        })
                    }

                }
            }
        }
    }

    async newResponse(form){

        this.setState({
            Files: [],
            list_form_response_identifier: [],
            list_form_response_versions: []
        })

        this.loadDataInForm(form, null, true, true);
    }

    async loadDataInForm(form, version, withVariable=true, initInput = true){

        let variableStorage = {};

        if(version && version.uniq_id){
            this.setState({
                uniqId: version.uniq_id
            })
        }
        else {
            this.setState({
                uniqId: null
            })
        }

        if(form && version) {
            console.log("res && version");

            var tmp_form = [];
            for (var res of form) {
                var findResponse = false;
                for (const resV of version.response) {
                    if(res.id === resV.id || res.id === resV.input_id){
                        //resV.type = res.type
                        res.value_text = resV.value_text
                        res.value_datetime = resV.value_datetime
                        res.value_num = resV.value_num
                        res.value_list_id = resV.value_list_id
                        //res = resV;
                        findResponse = true;
                        tmp_form.push(res)
                    }
                }
                if(!findResponse){
                    tmp_form.push(res)
                }
            }
            form = tmp_form;
        }
/*
        console.log("form=>");
        console.log(form);
*/
        if(withVariable === true){
            variableStorage = this.props.variableStorage;
        }

        if(form){

            for (const res of form) {
/*
                console.log("Input : ");
                console.log(res);
*/
                let initialisationData = {
                    "VariableContainer_tmp": {},
                    "input_value_tmp" : "",
                    "responses_tmp": null
                }


                //if(initInput){
                    initialisationData = initializeDefaultValue(res, variableStorage);
                    //console.log(initialisationData);
                //}

                let VariableContainer_tmp = initialisationData.VariableContainer_tmp;
                let input_value_tmp = initialisationData.input_value_tmp;
                let responses_tmp = initialisationData.responses_tmp;

                var inputValue = responses_tmp ? responses_tmp : input_value_tmp ? input_value_tmp : "";


                if((res.type === "nfc" || res.type === "qrcode")){
                    await this.setState({
                        ["v_"+res.id+"_treated"] : false,
                        ["v_"+res.id+"_loading"] : false,
                    })
                }

                /*if((res.type === "list" || res.type === "List_Multi") && Array.isArray(inputValue)){
                    inputValue = inputValue.join(",")
                }*/

                var responseValue = "";
                if((res.type === "list" || res.type === "List_Multi" || res.type === "list_btn" || res.type === "list_multi_btn") && Array.isArray(inputValue)){
                    responseValue = inputValue.join(",")
                }
                else {
                    responseValue = inputValue;
                }
/*
                console.log("setState : ");
                console.log("v_"+res.id + " type : "+res.type);
                console.log(inputValue);
*/
                await this.setState({
                    responses: inputValue ? {...this.state.responses, [res.id]:responseValue} : {...this.state.responses},
                    VariableContainer: {...this.state.VariableContainer, ...VariableContainer_tmp},
                    idName: {...this.state.idName, ["v_"+res.id] : inputValue},
                    isJsOutput: this.state.isJsOutput ? true : res.type === "js_output",
                    ["v_"+res.id] : inputValue,
                    ["v_"+res.id+"error"] : "",
                    ["v_"+res.id+"_default"] : inputValue,
                })

            }
        }

    }

    async handleChange(event) {
        const target = event.target;
        let value = target.value;
        const check = target.checked; //pour case à cocher
        const name = target.name;

        console.log("val => " + value);

        let input_id = name.replace(/v_/gm, "").replace(/\[(.*?)\]/gm, "");
        let truncated_name = name.replace(/v_/gm, "");

        if(this.props.Form){
            let my_input = this.props.Form.find((element) => element.id === input_id);



            if(my_input && (my_input.type === "nfc" || my_input.type === "qrcode") && my_input.interpret === "1"){
                await this.setState({
                    ["v_"+my_input.id+"_treated"] : false
                })
            }

            if(name.match(/v_/gm)){

                if(my_input.type === "time"){

                    await this.setState({
                        responses: {...this.state.responses, [truncated_name]:"1111-11-11 " + value},
                        idName: {...this.state.idName, [name]:"1111-11-11 " + value},
                        [name]: value
                    })
                }
                else if( my_input.type === "number"){
                    if(my_input.type === "integer"){
                        if(value){
                            value = parseInt(value.replace(/^\./gm, ""), 10);
                        }
                    }

                    if(parseFloat(value) > parseFloat(my_input.max_value)){
                        value = my_input.max_value;
                    } else if(parseFloat(value) < parseFloat(my_input.min_value)){
                        value = my_input.min_value;
                    }

                    await this.setState({
                        responses: {...this.state.responses, [truncated_name]:value},
                        idName: {...this.state.idName, [name]:value},
                        [name]: value
                    })
                }
                else if( my_input.type === "file" || my_input.type === "Picture" || my_input.type === "video"){
                    console.log("type file || Picture || video");
                    console.log(target.type);
                    console.log(target.files);
                    if(target.files){

                        console.log(this.state.Files);
                        console.log(this.state.Files[truncated_name]);

                        if(this.state.Files[truncated_name]){
                            let tmpFile =  this.state.Files[truncated_name];
                            let filesArray = Array.isArray(tmpFile) ? [...tmpFile, target.files[0]]: [tmpFile, target.files[0]];

                            console.log(tmpFile);
                            console.log(filesArray);

                            await this.setState({
                                Files: {...this.state.Files, [truncated_name]:filesArray},
                                [name]: value
                            })
                        } else {
                            await this.setState({
                                Files: {...this.state.Files, [truncated_name]:target.files[0]},
                                [name]: value
                            })
                        }
                    }
                }
                else if( my_input.type === "Boolean"){
                    await this.setState({
                        responses: {...this.state.responses, [truncated_name]:check},
                        idName: {...this.state.idName, [name]:check},
                        [name]: check
                    })
                }
                else if( my_input.type === "address"){

                    let propertie = name.match(/\[(.*?)\]/gm)[0].replace("['", "").replace("']", "");

                    let address_tmp = {...this.state.responses[input_id], [propertie] : value};

                    await this.setState({
                        responses: {...this.state.responses, [input_id]: address_tmp},
                        ["v_"+input_id]: address_tmp
                    })
                }
                else if( Array.isArray(value)){//select multiple

                    await this.setState({
                        responses: {...this.state.responses, [truncated_name]:value.join()},
                        idName: {...this.state.idName, [name]:value.join()},
                        [name]: value
                    })
                }
                else{
                    if(value.length === 0){

                        let responses_tmp = this.state.responses;
                        delete responses_tmp[truncated_name];

                        await this.setState({
                            responses: responses_tmp,
                            idName: {...this.state.idName, [name]:value},
                            [name]: value
                        })
                    } else {

                        await this.setState({
                            responses: {...this.state.responses, [truncated_name]:value},
                            idName: {...this.state.idName, [name]:value},
                            [name]: value
                        })
                    }
                }

                if(my_input && my_input.js_input){
                    let VariableContainer_tmp = constructVariableElement(my_input, target, check, value, {...store.getState().variableStorage, ...this.state.VariableContainer});

                    await this.setState({
                        VariableContainer: {...this.state.VariableContainer, ...VariableContainer_tmp}
                    })

                    store.getState().variableStorage = {...this.state.VariableContainer, ...VariableContainer_tmp};
                }


            } else {
                await this.setState({
                    [name]: value,
                })
            }

            if(this.state.isJsOutput){
                let jsOutputData = jsOutputTreatment(this.props.Form, this.state.VariableContainer);
                /*
                                console.log("jsOutputData - jsOutputData - jsOutputData - jsOutputData - jsOutputData");
                                console.log(jsOutputData);
                */
                await this.setState({
                    responses: {...this.state.responses, ...jsOutputData.responses_tmp},
                    idName: {...this.state.idName, ...jsOutputData.states_tmp},
                    VariableContainer: {...this.state.VariableContainer, ...jsOutputData.variable_container},
                    ...jsOutputData.states_tmp
                })

            }

        } else {
            await this.setState({
                [name]: value
            })
        }

        await this.hydrateFormData();

    }

    render() {
        const dyn_string  = this.props.t;
        let {title, instruction} = constructTitleAndInstruction(this.props.typeForm, this.props.application.getApp, this.props.formInfo, this.props.dataPage, dyn_string)
        let formStyle = this.props.Form ?
            {width:"100%", maxWidth:"800px", padding: this.props.typeForm === "RegisterForm" || this.props.typeForm === "ProfilForm" || this.props.typeForm === "market" || this.props.typeForm === "event_update" ? "0" : "0 15px"}
            :
            {padding:"0", width:"100%"};

        let historicalStyle = {paddingBottom:"20px"}
        let historicalTitleStyle = {paddingBottom:"10px"}

        if(this.state.loading){
            return <ShapperLoader width={this.state.width} height={this.state.height}/>
        } else {
            return (
                <React.Fragment>
                    <Container style={formStyle}>

                        {this.props.responsesHistory && Object.keys(this.props.responsesHistory).length > 0 && (

                            <div className="paper" style={historicalStyle}>
                                <Grid container spacing={0} alignItems="" justify="center">
                                {
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        style={{
                                            background: this.props.styles.button.backgroundColor,
                                            marginTop: "10px",
                                        }}
                                        onClick={() => this.newResponse(this.props.Form)}
                                    >
                                        <AddIcon style={{marginRight: "8px"}}/>
                                        Nouvelle réponse
                                    </Button>
                                }
                                {
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        style={{
                                            background: this.props.styles.button.backgroundColor,
                                            marginTop: "10px",
                                        }}
                                        onClick={() => this.showHistory()}
                                    >
                                        <RestoreIcon style={{marginRight: "8px"}}/>
                                        Voir l'historique
                                    </Button>
                                }
                                </Grid>

                                <Grid container spacing={0} alignItems="" justify="center">
                                    {
                                        this.state.list_form_response_identifier.map(key => {
                                            //console.log(key.form_response_identifier);
                                            return (
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    style={{
                                                        background: this.props.styles.button.backgroundColor,
                                                        marginTop: "10px",
                                                    }}
                                                    onClick={() => this.showVersions(key.form_response_identifier)}
                                                >
                                                    <EditIcon style={{marginRight: "8px"}}/>
                                                    {key.form_response_identifier}
                                                </Button>

                                            )
                                        })
                                    }
                                </Grid>

                                <Grid container spacing={1} alignItems="" justify="center">
                                    {
                                        this.state.list_form_response_versions.map(key => {
                                            //console.log(key.form_response_identifier);
                                            let version = (Number(key.version) + 1);

                                            return (
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    style={{
                                                        background: this.props.styles.button.backgroundColor,
                                                        marginTop: "10px",
                                                    }}
                                                    onClick={() => this.loadDataInForm(JSON.parse(JSON.stringify(this.props.Form)), key)}
                                                >
                                                    v{version} - {dateToLocaleString(key.date_response, this.props.t)}
                                                </Button>

                                            )
                                        })
                                    }
                                </Grid>
                            </div>

                        )
                        }

                        <Paper className="paper" style={{backgroundColor: "white", padding: "20px", marginTop: (this.state.width <= 800) ? "20px" : "100px", width:"100%", textAlign:"left", marginBottom:(this.state.width <= 800) ? "20px" : "200px"}}>

                            <FormulaireTitle title={title} />

                            <FormulaireInstruction instruction={instruction} />

                            <form className="form">
                                {this.props.typeForm === "RegisterForm" && (
                                    <FormulaireRegister formState={this.state} handleChange={this.handleChange} formData={this.state.formData} getApp={this.props.application.getApp}/>
                                )}

                                {this.props.typeForm === "ProfilForm" && (
                                    <FormulaireProfil formState={this.state} handleChange={this.handleChange} formData={this.state.formData} getApp={this.props.application.getApp}/>
                                )}

                                {this.props.typeForm === "event_update" && (
                                    <FormulaireEventUpdate formState={this.state} handleChange={this.handleChange} />
                                )}

                                {this.props.Form && (
                                    <FormulaireDynamic
                                        Form={this.props.Form}
                                        idName={this.state.idName}
                                        formState={this.state}
                                        handleChange={this.handleChange}
                                        handleSignature={this.handleSignature}
                                        clearStateSignature={this.clearStateSignature}
                                        resetState={this.resetState}
                                        handleTagTreatment={this.handleTagTreatment}
                                        Files={this.state.Files}
                                    />
                                )}

                                <div style={{textAlign:"end"}}>
                                <FormulaireButton
                                    typeForm={this.props.typeForm}
                                    formState={this.state}
                                    getApp={this.props.application.getApp}
                                    dataPage={this.props.dataPage}
                                    formInfo={this.props.formInfo}
                                    onSubmitProfil={this.onSubmitProfil}
                                    onSubmitRegister={this.onSubmitRegister}
                                    onSubmitCreateOrder={this.onSubmitCreateOrder}
                                    onSubmitDynamicForm={this.onSubmitDynamicForm}
                                    setUpdateEvent={this.setUpdateEvent}
                                    setAnnounce={this.setAnnounce}
                                />
                                </div>

                                {/*affichage d'autres composants (bouton ..)*/}
                                {this.props.children}
                            </form>
                        </Paper>

                        {/* popup*/}

                        <PopUpLoader active={this.state.loader} />

                        <ConfirmRegister
                            isOpen={this.state.confirm_register}
                            onClose={() => this.setState({confirm_register: false})}
                            showLogin={this.props.showLogin}
                        />

                        <ConfirmProfilForm
                            isOpen={this.state.confirm_profil_form}
                            onClose={() => this.setState({confirm_profil_form: false})}
                        />

                        <ErrorForm
                            isErrorForm={this.state.error_form}
                            firstError={this.state.first_error}
                            onClose={() => this.setState({error_form: false, first_error:""})}
                        />

                        <ConfirmDynamicForm
                            dataPage={this.props.dataPage}
                            isOpen={this.state.confirm_dyn_form}
                            onClose={() => this.setState({confirm_dyn_form: false})}
                            validationText={this.state.validation_text_dyn_form}
                        />
                    </Container>
                </React.Fragment>
            );
        }
    }

    async handleSignature(name, file){
        await this.setState({
            [name]: file.name,
            Files: {...this.state.Files, [name.replace(/v_/gm, "")]:file}
        })
    }

    async clearStateSignature(name){
        await this.setState({
            [name]: "",
            Files: {...this.state.Files, [name.replace(/v_/gm, "")]:null}
        });
    }

    async resetState(id, type, title = null, index = null, handleIndex = false){
        if(type === "nfc" || type === "qrcode"){
            let tmp_responses = this.state.responses;
            delete tmp_responses[id];

            let tmp_idName  = this.state.idName;
            delete tmp_idName[id];

            await this.setState({
                ["v_"+id]: "",
                ["v_"+id+"_treated"]: false,
                ["v_"+id+"_loading"]: false,
                responses: tmp_responses,
                idName: tmp_idName
            })
        } else if (type === "Picture" || type === "video" || type === "file"){
            if(title){
                let index  = this.state.Files[id].findIndex(element => element.name === title)
                let tmp_files = [
                    ...this.state.Files[id].slice(0, index),
                    ...this.state.Files[id].slice(index + 1)
                ]

                await this.setState({
                    Files: {...this.state.Files, [id]:tmp_files.length === 1 ? tmp_files[0] : tmp_files}
                })
            } else if(handleIndex){
                let array = this.state["v_"+id+'_default'].split(";");
                let newArray = [
                    ...array.slice(0, index),
                    ...array.slice(index + 1)
                ];

                let result = newArray.length === 1 ? newArray[0] : newArray.join(";");
                let tmp_responses = {...this.state.responses, [id]:result};

                await this.setState({
                    ["v_"+id+'_default']: result,
                    responses: tmp_responses,
                    formData: {...this.state.formData, responses: tmp_responses}
                })
            } else {
                await this.setState({
                    ['v_' + id]: "",
                    Files: {...this.state.Files, [id]:null}
                })
            }
        } else {
            if(REGEX_URL.test(this.state['v_'+id])){
                await this.setState({
                    ['v_' + id]: "",
                    responses: this.props.typeForm !== "dynamic" ? {...this.state.responses, [id]:""} : {...this.state.responses},
                })
            }
        }
    }

    validate = async () => {
        let isError = false;
        const errors = {};
        const dyn_string  = this.props.t;

        if(this.props.typeForm === "RegisterForm" || this.props.typeForm === "ProfilForm"){
            Object.entries(this.state.formData).forEach(([key_form, res]) =>
            {
                if(!res && key_form !== "Email"){
                    if((key_form === "login") && (this.props.application.getApp.is_mail_login === "1")){
                        errors[key_form+"error"] = "";
                    } else {
                        isError = true;
                        errors[key_form+"error"] = decodeHtmlAscii(dyn_string("form_alert_msg_err_not_filled", {nom_champ: key_form}));
                    }
                }else if (!res && key_form === "Email"){
                    isError = true;
                    errors[key_form+"error"] = decodeHtmlAscii(dyn_string("form_alert_msg_err_not_filled", {nom_champ: key_form}));
                }else{
                    errors[key_form+"error"] = "";
                }

                if(res && key_form === "Email" && !res.match(/^\S+@\S+\.\S+$/)){
                    isError = true;
                    errors[key_form+"error"] = decodeHtmlAscii(dyn_string('profile_alert_msg_err_confirm_mail', {mail: key_form}));
                }
            })

            if(this.state.formData.pass && this.state["confirm_password"]){
                if (this.state['password'] !== this.state["confirm_password"]){
                    isError = true;
                    errors["passerror"] = dyn_string('profile_alert_msg_err_same_pass');
                }
            }

        }

        if(this.props.Form) {

/*
            console.log("this.state => ");
            console.log(this.state);
*/
            this.props.Form.forEach(res => {
                errors["v_"+res.id+"error"] = '';
                let regex = "";
                let isRegex = false;
                if(res.express_regul){
                    regex = new RegExp(res.express_regul, "g");
                    isRegex = true
                }
                let isLengthMax = false;
                if(res.length_value && res.type !== 'list_btn' && res.type !== 'list_multi_btn'){
                    isLengthMax = res.length_value;
                }
                const exceptType = ["address", "Picture", "video"]

                if(!this.state["v_"+res.id] && parseInt(res.mandatory) === 1 && !exceptType.includes(res.type)){
                    if (res.conditional_script !== null){
                        if (conditionalScript(this.state.idName, res.conditional_script)){
                            isError = true;
                            errors["v_" + res.id + "error"] = res.error_message ? res.error_message : decodeHtmlAscii(dyn_string("form_alert_msg_err_not_filled", {nom_champ: res.label}));
                        }
                    } else {
                        isError = true;
                        errors["v_" + res.id + "error"] = res.error_message ? res.error_message : decodeHtmlAscii(dyn_string("form_alert_msg_err_not_filled", {nom_champ: res.label}));
                    }

                }else if(this.state["v_"+res.id] && this.state["v_"+res.id].length === 0 && parseInt(res.mandatory) === 1){
                    if (res.conditional_script !== null){
                        if (conditionalScript(this.state.idName, res.conditional_script)){
                            isError = true;
                            errors["v_" + res.id + "error"] = res.error_message ? res.error_message : decodeHtmlAscii(dyn_string("form_alert_msg_err_not_filled", {nom_champ: res.label}));
                        }
                    } else {
                        isError = true;
                        errors["v_" + res.id + "error"] = res.error_message ? res.error_message : decodeHtmlAscii(dyn_string("form_alert_msg_err_not_filled", {nom_champ: res.label}));
                    }

                }else if(isRegex && this.state["v_"+res.id] && !this.state["v_"+res.id].match(regex)){
                    isError = true;
                    errors["v_"+res.id+"error"] = res.error_message ? res.error_message : decodeHtmlAscii(dyn_string("form_alert_msg_err_not_filled", {nom_champ: res.label}));

                } else if (isLengthMax && this.state["v_"+res.id]) {
                    if (this.state["v_"+res.id].length > isLengthMax){
                        isError = true;
                        errors["v_"+res.id+"error"] = decodeHtmlAscii(dyn_string("form_alert_msg_err_string_too_long", {champ: res.label, length_max: isLengthMax}));
                    }

                }else if(res.type === "Picture"){
                    if (res.conditional_script !== null){
                        if (conditionalScript(this.state.idName, res.conditional_script)){
                            //isError = true;
                            //errors["v_" + res.id + "error"] = res.error_message ? res.error_message : decodeHtmlAscii(dyn_string("form_alert_msg_err_not_filled", {nom_champ: res.label}));

                            if(parseInt(res.mandatory) === 1 && (!this.state.responses[res.id] && !this.state["v_"+res.id])){
                                isError = true;
                                errors["v_" + res.id + "error"] = res.error_message ? res.error_message : decodeHtmlAscii(dyn_string("form_alert_msg_err_not_filled", {nom_champ: res.label}));
                            }
                            if(this.state["v_"+res.id] && !this.state["v_"+res.id].match(/\.(?:jpg|jpeg|png|PNG|JPG|JPEG)/g)){
                                isError = true;
                                errors["v_"+res.id+"error"] = dyn_string('form_alert_msg_err_image_format');
                            }

                        }
                    } else {
                        //isError = true;
                        //errors["v_" + res.id + "error"] = res.error_message ? res.error_message : decodeHtmlAscii(dyn_string("form_alert_msg_err_not_filled", {nom_champ: res.label}));

                        if(parseInt(res.mandatory) === 1 && (!this.state.responses[res.id] && !this.state["v_"+res.id])){
                            isError = true;
                            errors["v_" + res.id + "error"] = res.error_message ? res.error_message : decodeHtmlAscii(dyn_string("form_alert_msg_err_not_filled", {nom_champ: res.label}));
                        }
                        if(this.state["v_"+res.id] && !this.state["v_"+res.id].match(/\.(?:jpg|jpeg|png|PNG|JPG|JPEG)/g)){
                            isError = true;
                            errors["v_"+res.id+"error"] = dyn_string('form_alert_msg_err_image_format');
                        }
                    }

                }else if(res.type === "video"){

                    if (res.conditional_script !== null) {
                        if (conditionalScript(this.state.idName, res.conditional_script)) {
                            //isError = true;
                            //errors["v_" + res.id + "error"] = res.error_message ? res.error_message : decodeHtmlAscii(dyn_string("form_alert_msg_err_not_filled", {nom_champ: res.label}));

                            if (parseInt(res.mandatory) === 1 && (!this.state.responses[res.id] && !this.state["v_" + res.id])) {
                                isError = true;
                                errors["v_" + res.id + "error"] = res.error_message ? res.error_message : decodeHtmlAscii(dyn_string("form_alert_msg_err_not_filled", {nom_champ: res.label}));
                            }

                            if (this.state["v_" + res.id] && !this.state["v_" + res.id].match(/\.(?:mp4|mov)/g)) {
                                isError = true;
                                errors["v_" + res.id + "error"] = res.label + " (" + dyn_string('form_alert_msg_err_video_format') + ") ";
                            }
                        }
                    }
                    else {
                        if (parseInt(res.mandatory) === 1 && (!this.state.responses[res.id] && !this.state["v_" + res.id])) {
                            isError = true;
                            errors["v_" + res.id + "error"] = res.error_message ? res.error_message : decodeHtmlAscii(dyn_string("form_alert_msg_err_not_filled", {nom_champ: res.label}));
                        }

                        if (this.state["v_" + res.id] && !this.state["v_" + res.id].match(/\.(?:mp4|mov)/g)) {
                            isError = true;
                            errors["v_" + res.id + "error"] = res.label + " (" + dyn_string('form_alert_msg_err_video_format') + ") ";
                        }
                    }
                }
                else if(res.type === "file") {

                    if (res.conditional_script !== null) {
                        if (conditionalScript(this.state.idName, res.conditional_script)) {
                            //isError = true;
                            //errors["v_" + res.id + "error"] = res.error_message ? res.error_message : decodeHtmlAscii(dyn_string("form_alert_msg_err_not_filled", {nom_champ: res.label}));

                            if (parseInt(res.mandatory) === 1 && (!this.state.responses[res.id] && !this.state["v_" + res.id])) {
                                isError = true;
                                errors["v_" + res.id + "error"] = res.error_message ? res.error_message : decodeHtmlAscii(dyn_string("form_alert_msg_err_not_filled", {nom_champ: res.label}));
                            }

                            if (this.state["v_" + res.id] && !this.state["v_" + res.id].match(/\.(?:pdf|PDF|ppt|PPT|pptx|PPTX)/g)) {
                                isError = true;
                                errors["v_" + res.id + "error"] = res.label + " (" + dyn_string('form_alert_msg_err_video_format') + ") ";
                            }

                        }
                    }
                    else {
                        if (parseInt(res.mandatory) === 1 && (!this.state.responses[res.id] && !this.state["v_" + res.id])) {
                            isError = true;
                            errors["v_" + res.id + "error"] = res.error_message ? res.error_message : decodeHtmlAscii(dyn_string("form_alert_msg_err_not_filled", {nom_champ: res.label}));
                        }

                        if (this.state["v_" + res.id] && !this.state["v_" + res.id].match(/\.(?:pdf|PDF|ppt|PPT|pptx|PPTX)/g)) {
                            isError = true;
                            errors["v_" + res.id + "error"] = res.label + " (" + dyn_string('form_alert_msg_err_file_format') + ") ";
                        }
                    }

                }else if(this.state["v_"+res.id] && res.type === "mail" && !this.state["v_"+res.id].match(/^\S+@\S+\.\S+$/)){
                    isError = true;
                    errors["v_"+res.id+"error"] = decodeHtmlAscii(dyn_string('profile_alert_msg_err_confirm_mail', {mail: res.label}));

                }else if(res.type === "address" && parseInt(res.mandatory) === 1){
                    let address = this.state["v_" + res.id];

                    if(Object.keys(address).length === 0 || !address.address1 || !address.city || !address.country || !address.zip){
                        isError = true;
                        errors["v_" + res.id + "error"] = res.error_message ? res.error_message : decodeHtmlAscii(dyn_string("form_alert_msg_err_not_filled", {nom_champ: res.label}));
                    }

                }

                /* vérification sur les tags traités pour ne pas envoyer le formulaire si la donnée à été modifiée
                else if((res.type === "nfc" || res.type === "qrcode") && res.interpret === "1" && this.state["v_"+res.id] && !this.state["v_"+res.id+"_treated"]){
                    isError = true;
                    errors["v_" + res.id + "error"] = res.error_message ? res.error_message : decodeHtmlAscii(dyn_string("form_alert_msg_err_not_treated", {nom_champ: res.label}));
                }
                */
            });
        }

        let firstError = Object.values(errors).find(element => element.length !== 0) || "";

        await this.setState({
            ...this.state,
            ...errors,
            first_error: firstError ? firstError : "",
            error_form: !!firstError
        });

        return isError;
    };

    onSubmitCreateOrder = async (e) => {
        e.preventDefault();

        let data = constructDynamicFormData (this.state.formData, this.props.Form, this.state.responses, this.state.idName, this.props.formInfo.formulaire_id);

        const err = await this.validate();

        if (!err) {
            this.props.createOrder({FormData: data, products: this.props.products});
        }
    };

    onSubmitDynamicForm = async (e) => {
        e.preventDefault();

        let formulaireId = this.props.dataPage ? this.props.dataPage.formulaire_id : this.props.formInfo.formulaire_id

        let data = constructDynamicFormData (this.state.formData, this.props.Form, this.state.responses, this.state.idName, formulaireId, this.state.uniqId);

        const err = await this.validate();

        if (!err) {
            console.log("addVariableData");
            console.log(this.state.VariableContainer);
            this.props.addVariableData(this.state.VariableContainer);

            if(this.props.typeForm === "local"){

                if (this.props.dataPage.form && this.props.dataPage.form.syn_content_redirect && this.props.routes.RoutePathArray[this.props.dataPage.form.syn_content_redirect]){
                    pushHistory(this.props.routes.RoutePathArray[this.props.dataPage.form.syn_content_redirect].url)
                }
            } else {
                this.setState({ disabled: true, loader:true});

                //RECUPERATION FICHIER
                let upload = await this.uploadFiles(data)
                data = upload.data;

                if(upload.pass){
                    await  setResponseDynamicForm(data).then(async (res) => {
                        if (res.data.success) {
                            if(this.props.typeForm === "event_creation"){
                                this.props.requestGetPlanningsData();
                                this.props.isEventCreated();
                            } else {
                                if(res.data.result){
                                    await this.setState({
                                        confirm_dyn_form: true,
                                        validation_text_dyn_form: res.data.result.validation_text,
                                        disabled: false,
                                        loader: false
                                    });
                                } else {
                                    await this.setState({error_form: true, disabled: false, loader: false})
                                    console.log(res.error)
                                }
                            }
                        } else {
                            await this.setState({error_form: true, disabled: false, loader: false})
                            console.log(res.error)
                        }
                    }).catch(async (e) => {
                        await this.setState({error_form: true, disabled: false, loader: false})
                        console.log(e)
                    });
                }
            }
        }
    };

    onSubmitRegister = async (e) => {
        e.preventDefault();

        const err = await this.validate();

        if (!err) {

            let data = this.state.formData;

            this.setState({loader: true})

            //RECUPERATION FICHIER
            let upload = await this.uploadFiles(data)
            data = upload.data;

            if(upload.pass){
                Register(data).then(res =>{
                    if (res.success){
                        this.setState({confirm_register: true, loader:false})
                    }else{
                        this.setState({error_form: true, loader:false, first_error: res.error})
                        console.log(res.error)
                    }
                })
            }
        }

    };

    onSubmitProfil = async (e) => {
        e.preventDefault();

        const err = await this.validate();

        if (!err) {

            let data = this.state.formData;

            this.setState({loader: true})

            //RECUPERATION FICHIER
            let upload = await this.uploadFiles(data)
            data = upload.data;

            if(upload.pass){
                SetProfilForm(data).then(async (res) =>{
                    if (res.success){
                        if(cookies.get("login") !== data.login || cookies.get("password") !== data.pass){
                            let new_password = data.pass ? data.pass : cookies.get("password");
                            let login = this.props.application.getApp.is_mail_login === "1" ? data.mail : data.login;

                            if (this.props.application.remember_me){
                                const date = new Date(Date.now());
                                date.setFullYear(date.getFullYear() + 1);
                                cookies.set("login", login, { path: "/", expires: date});
                                cookies.set("password", new_password, {path: "/", expires: date});
                            } else {
                                cookies.set("login", login, { path: "/" });
                                cookies.set("password", new_password, {path: "/",});
                            }
                        }

                        await this.setState({confirm_profil_form: true, loader:false});
                    }else{
                        await this.setState({error_form: true, loader:false, first_error: res.error})
                    }
                })
            }
        }
    };

    setUpdateEvent(e){
        e.preventDefault()

        let startTime;
        let endTime;

        if(this.state.evt_update_start_time){
            startTime = /([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]/g.test(this.state.evt_update_start_time) ? this.state.evt_update_start_time : this.state.evt_update_start_time + ":00";
        } else {
            startTime = ""
        }

        if(this.state.evt_update_end_time){
            endTime = /([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]/g.test(this.state.evt_update_end_time) ? this.state.evt_update_end_time : this.state.evt_update_end_time + ":00";
        } else {
            endTime = ""
        }

        this.props.updateEvent({
            title: this.state.evt_update_title || "",
            description: this.state.evt_update_description || "",
            startDate: this.state.evt_update_start_date || "",
            startTime: startTime,
            endDate: this.state.evt_update_end_date || "",
            endTime: endTime,
            addressStreet1: this.state.evt_update_address_street1 || "",
            addressStreet2: this.state.evt_update_address_street2 || "",
            addressCity: this.state.evt_update_address_city || "",
            addressCountry: this.state.evt_update_address_country || "",
            addressZip: this.state.evt_update_address_zip || "",
        });
    }

    setAnnounce = async (e) => {
        e.preventDefault();

        let formulaireId = this.props.market.success ? this.props.market.result.form_announce.id : null

        let data = constructDynamicFormData (this.state.formData, this.props.Form, this.state.responses, this.state.idName, formulaireId);

        const err = await this.validate();

        if (!err) {
            this.setState({ disabled: true, loader:true});

            //RECUPERATION FICHIER
            let upload = await this.uploadFiles(data)
            data = upload.data;

            if(upload.pass){

                Object.entries(data.responses).forEach(field => {
                    let input_id = field[0];
                    let value = field[1];
                    let form_input = this.props.Form.find(element => element.id === input_id)

                    if(form_input){
                        data[form_input.arg_output] = value;
                    }

                    delete data.responses;
                })

                await  setMrktAnnounce(data)
                    .then(async (res) => {
                    if (res.data.success) {
                        if(res.data.result){
                            await this.setState({
                                confirm_dyn_form: true,
                                validation_text_dyn_form: this.props.market.result.form_announce.validation_text,
                                disabled: false,
                                loader: false
                            });
                        } else {
                            await this.setState({error_form: true, disabled: false, loader: false})
                            console.log(res.data)
                        }
                    } else {
                        await this.setState({error_form: true, disabled: false, loader: false})
                        console.log(res.data.error)
                    }
                })
                    .catch(async (e) => {
                    await this.setState({error_form: true, disabled: false, loader: false})
                    console.log(e)
                });
            }
        }
    };

    uploadFiles = async (data) => {
        let pass = true;
        const dyn_string  = this.props.t;

        if(Object.keys(this.state.Files).length !== 0) {
            await Promise.all(Object.entries(this.state.Files).map(async (res) => {

                if(res){

                    let input_id = res[0];

                    if(Array.isArray(res[1])){
                        let tmp_data = [];

                        for(let media of res[1]){
                            let loopPass = pass;

                            await uploadMediaStatic(media)
                                .then(async(res) => {

                                    if (res.success) {
                                        if(res.result && res.result.file_url){
                                            tmp_data.push(res.result.file_url)
                                        } else {
                                            loopPass = false;
                                            await this.setState({error_form: true, loader:false, disabled: false, first_error: dyn_string('form_alert_msg_err_send_form')});
                                        }
                                    } else {
                                        loopPass = false;
                                        await this.setState({error_form: true, loader:false, disabled: false, first_error:res.error});
                                    }
                                })
                                .catch(async (e) => {
                                    loopPass = false;
                                    await this.setState({error_form: true, loader:false, disabled: false, first_error: dyn_string('form_alert_msg_err_send_form')});
                                    console.log(e);
                                })

                            pass = loopPass
                        }

                        if (data.responses[input_id]){
                            let tmp_responses = data.responses[input_id].split(";")
                            data.responses[input_id] = tmp_responses.concat(tmp_data).join(";")
                        } else {
                            data.responses[input_id] = tmp_data.join(";")
                        }

                    } else {

                        await uploadMediaStatic(res[1])
                            .then(async (res) => {

                                if (res.success) {
                                    if(res.result && res.result.file_url){
                                        if (data.responses[input_id]){

                                            let tmp_responses = data.responses[input_id].split(";")
                                            tmp_responses.push(res.result.file_url)
                                            data.responses[input_id] = tmp_responses

                                        } else {
                                            data.responses[input_id] = res.result.file_url;
                                        }
                                    } else {
                                        pass = false;
                                        await this.setState({error_form: true, loader:false, disabled: false, first_error: dyn_string('form_alert_msg_err_send_form')});
                                    }
                                } else {
                                    pass = false;
                                    await this.setState({error_form: true, loader:false, disabled: false, first_error:res.error});
                                }

                            })
                            .catch(async (e) => {
                                pass = false;
                                await this.setState({error_form: true, loader:false, disabled: false, first_error: dyn_string('form_alert_msg_err_send_form')});
                                console.log(e);
                            })
                    }
                }
            }));
        }

        return {
            data:data,
            pass:pass
        }
    }

    hydrateFormData = async () => {
        let formData_tmp = {};

        if(this.props.typeForm === "ProfilForm"){
            formData_tmp = {
                mobinauteId : this.props.profil.mobinaute.id,
                appId : this.props.application.getApp.id,
                login : this.state.login,
                Email : this.state.Email,
                firstname : this.state.firstname,
                lastname : this.state.lastname,
                responses : this.state.responses
            }

            if (parseInt(this.props.application.getApp.password_update) === 1){
                if(this.state.password){
                    formData_tmp.pass = md5(this.state.password);
                } else {
                    delete formData_tmp.pass;
                }
            }

        }
        else if(this.props.typeForm === "RegisterForm"){
            formData_tmp = {
                deviceId : parseInt(this.props.application.deviceId),
                appId : parseInt(this.props.application.getApp.id),
                login : this.state.login,
                Email : this.state.Email,
                firstname : this.state.firstname,
                lastname : this.state.lastname,
                responses : this.state.responses
            }

            if(this.state.password){
                formData_tmp.pass = md5(this.state.password);
            } else {
                formData_tmp.pass = undefined;
            }
        }

        await this.setState({
            formData: {...this.state.formData, ...formData_tmp}
        })

    }

    handleTagTreatment = async (value, inputId, isQrCode=false) => {
        let treatment = true;
        let tagUid = value;
        await this.setState({
            ["v_"+inputId+"_loading"]: true,
        })

        if(isQrCode){
            let input = this.props.Form.find(element => element.id === inputId)

            if(input && input.interpret === "0"){
                treatment = false;

                await this.setState({
                    ["v_"+inputId]: value,
                    ["v_"+inputId+"_loading"]: false,
                    responses: {...this.state.responses, [inputId]:value},
                    idName: {...this.state.idName, [inputId]:value}
                })
            }
        }


        if(treatment){
            tagTreatment(tagUid)
                .then(async (response) => {

                    if(response.success){
                        let tag = response.result;
                        let value;

                        if(tag.target_type === "content"){
                            value = tag.value.title

                            if(tag.value.subtitle){
                                value += " - "+tag.value.subtitle
                            }

                        } else if(tag.target_type === "mobinaute"){
                            value = tag.value.login
                        } else {
                            if(isJsonParsable(tag.value)){
                                console.warn("JSON : ", tag.value)
                            } else {
                                value = tag.value;
                            }
                        }

                        await this.setState({
                            ["v_"+inputId]: value,
                            ["v_"+inputId+"_treated"]: true,
                            ["v_"+inputId+"_loading"]: false,
                            responses: {...this.state.responses, [inputId]:value},
                            idName: {...this.state.idName, [inputId]:value}
                        })

                    } else {
                        let tmp_responses = this.state.responses;
                        delete tmp_responses[inputId];

                        let tmp_idName  = this.state.idName;
                        delete tmp_idName[inputId];

                        await this.setState({
                            error_form: true,
                            disabled: false,
                            loader: false,
                            first_error:response.error,
                            ["v_"+inputId]: "",
                            ["v_"+inputId+"_treated"]: false,
                            ["v_"+inputId+"_loading"]: false,
                            responses: tmp_responses,
                            idName: tmp_idName
                        })
                    }
                })
                .catch(e => {
                    console.log(e)
                })
        }
    }
}
const mapStateToProps = (state) => ({
    variableStorage: state.variableStorage,
    styles: state.style,
    application: state.application,
    profil: state.profil,
    routes: state.routes,
    market: state.market
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            addVariableData,
            requestGetPlanningsData,
        },
        dispatch
    );

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Formulaire);
