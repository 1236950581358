import React from "react";
import {bindActionCreators, compose} from "redux";
import {initPlaylist, nextPlaylistElement, previousPlaylistElement, selectPlaylistElement} from "../../actions";
import {connect} from "react-redux";
import ReactPlayer from "react-player";
import FlatList from "flatlist-react";
import {Col, Row, Container} from "react-bootstrap";
import dompurify from 'dompurify';
import {Avatar, Button, ButtonGroup, List, ListItem, ListItemAvatar, ListItemText, Slider, Typography} from "@material-ui/core"
import {PlayArrow} from "@material-ui/icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBackward, faExpand, faForward, faInfinity, faPause, faPlay, faSquareFull} from "@fortawesome/free-solid-svg-icons";
import {findDOMNode} from "react-dom";
import screenfull from "screenfull";
import {withTranslation} from "react-i18next";

class Playlist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,

            // Gestion du player
            playing: false,
            duration: 0, // Durée du morceau (sec)
            played: 0, // Durée jouée (sec)
            loop: false, // Écoute les morceaux en boucle
        };

        this.handlePlayPause = this.handlePlayPause.bind(this);
        this.handleDuration = this.handleDuration.bind(this);
        this.handleProgress = this.handleProgress.bind(this);
        this.handleSeekChange = this.handleSeekChange.bind(this);
        this.handleSeekMouseDown = this.handleSeekMouseDown.bind(this);
        this.handleSeekMouseUp = this.handleSeekMouseUp.bind(this);
        this.handleLoop = this.handleLoop.bind(this);
    }

    static formatSeconds(duration){
        const isMoreThanOneHour = duration > 3600;
        return new Date(duration * 1000)
            .toISOString()
            .substr(
                isMoreThanOneHour ? 11 : 14,
                isMoreThanOneHour ? 8 : 5
            );
    }

    render(){
        const currentElementToPlay = this.props.list && typeof this.props.currentIndex !== "undefined" ? this.props.list[this.props.currentIndex] : false;
        if(!currentElementToPlay){
            return (<h1>No elements could be displayed</h1>)
        }

        return (
            <Container className="mb-3">
                <Row>
                    <Col sm={12} md={6} xl={8}>
                        <h1 className="mb-0">
                            {currentElementToPlay.title}
                        </h1>
                        <Typography variant="overline" display="block" gutterBottom>
                            {currentElementToPlay.subtitle}
                        </Typography>

                        <ReactPlayer
                            ref={this.ref}
                            width="100%"
                            url={currentElementToPlay.youtube_url}
                            playing={this.state.playing}
                            onPlay={this.handlePlay}
                            onPause={this.handlePause}
                            onDuration={this.handleDuration}
                            onProgress={this.handleProgress}
                            onEnded={this.props.nextPlaylistElement}
                            loop={this.state.loop}
                        />

                        <div className="d-flex justify-content-center align-items-center">
                            <Typography className="mr-1 ml-1">
                                {Playlist.formatSeconds(this.state.played)}
                            </Typography>
                            <Slider
                                className="mr-4 ml-4"
                                defaultValue={0}
                                step={1}
                                min={0}
                                max={this.state.duration}
                                value={this.state.played}
                                valueLabelDisplay="auto"
                                onMouseDown={this.handleSeekMouseDown}
                                onChangeCommitted={this.handleSeekMouseUp}
                                onChange={this.handleSeekChange}
                            />
                            <Typography className="mr-1 ml-1">
                                {Playlist.formatSeconds(this.state.duration)}
                            </Typography>
                        </div>

                        <div className="d-flex justify-content-center align-items-center mb-3">
                            <ButtonGroup size="large" color="primary" aria-label="Player controls" className="mt-2">
                                <Button onClick={this.props.previousPlaylistElement}>
                                    <FontAwesomeIcon icon={faBackward} />
                                </Button>
                                <Button onClick={this.handlePlayPause}>
                                    {!this.state.playing ?
                                        (<FontAwesomeIcon icon={faPlay} />):
                                        (<FontAwesomeIcon icon={faPause} />)
                                    }
                                </Button>
                                <Button onClick={this.props.nextPlaylistElement}>
                                    <FontAwesomeIcon icon={faForward} />
                                </Button>
                                <Button onClick={this.handleResetPlayer}>
                                    <FontAwesomeIcon icon={faSquareFull} />
                                </Button>
                                <Button onClick={this.handleOpenFullScreen}>
                                    <FontAwesomeIcon icon={faExpand} />
                                </Button>
                                <Button onClick={this.handleLoop} variant={this.state.loop ? 'contained' : 'outlined'}>
                                    <FontAwesomeIcon icon={faInfinity} />
                                </Button>
                            </ButtonGroup>
                        </div>

                        <Typography className="mr-1 ml-1" dangerouslySetInnerHTML={{ __html: dompurify.sanitize(currentElementToPlay.content) }} gutterBottom>
                        </Typography>
                    </Col>

                    <Col className="d-flex align-items-center">
                        <List style={{
                            overflowY: "scroll",
                            maxHeight: "50vh"
                        }} component="nav" aria-label="medias list">
                            <FlatList
                                list={this.props.list}
                                renderItem={this.renderMedia}
                                renderWhenEmpty={() =><h1>Aucune vidéo à suivre</h1>}
                            />
                        </List>
                    </Col>
                </Row>
            </Container>
        );
    }

    renderMedia = (media,idx) => (
        <ListItem key={idx} selected={idx === this.props.currentIndex} onClick={() => {
            this.props.selectPlaylistElement(idx)
        }}>
            <ListItemAvatar>
                <Avatar>
                    <PlayArrow />
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={media.title} />
        </ListItem>
    );

    componentDidMount() {
        window.scrollTo(0,0);
        window.addEventListener("resize", this.updateDimensions);

        this.props.initPlaylist({
            list: this.props.dataPage.nodes,
            currentIndex: 0
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions = () => this.setState({ width: window.innerWidth, height: window.innerHeight });

    ref = player => {
        this.player = player
    }

    handlePlay = () => this.setState({playing: true});
    handlePause = () => this.setState({playing: false});
    handlePlayPause = () => this.setState({playing: !this.state.playing});
    handleOpenFullScreen = () => screenfull.request(findDOMNode(this.player));
    handleDuration = duration => this.setState({duration});
    handleLoop = () => this.setState({loop: !this.state.loop});
    handleProgress = e => {
        if (!this.state.seeking) {
            this.setState({played: e.playedSeconds});
        }
    }
    handleSeekMouseDown = () => this.setState({ seeking: true });
    handleSeekChange = (e, newValue) => this.setState({ played: parseFloat(newValue) });
    handleSeekMouseUp = (e, newValue) => {
        this.setState({ seeking: false });
        this.player.seekTo(parseFloat(newValue),'seconds');
    }
    handleResetPlayer = () => this.player.seekTo(0);
}



const mapStateToProps = (state) => ({
    list: state.playlistPlayer.list,
    currentIndex: state.playlistPlayer.currentIndex
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            initPlaylist,
            nextPlaylistElement,
            previousPlaylistElement,
            selectPlaylistElement
        },
        dispatch
    );

export default compose( withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Playlist);
