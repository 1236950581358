import {useTranslation} from "react-i18next";
import React from "react";
import {REGEX_URL} from "../Formulaire.functions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import Container from "reactstrap/es/Container";
import {Fab, Input} from "@material-ui/core";
import {Row} from "react-bootstrap";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import {useSelector} from "react-redux";
import MediaQuery from 'react-responsive'
import {buildShapperPath} from "../../../helpers/Tools";

export const FormulaireDynamicPicture = (props) => {
    let isUrl_img = props.formState['v_' + props.res.id+'_default'] && props.formState['v_' + props.res.id+'_default'] !== "" && REGEX_URL.test(buildShapperPath(props.formState['v_' + props.res.id+'_default']));

    console.log("isUrl_img");
    console.log(isUrl_img);
    console.log(props.formState['v_' + props.res.id+'_default']);

    if(!isUrl_img){
        return (
            <AddPictures {...props} />
        )
    } else {
        return (
            <DisplayPictures {...props} />
        )
    }
};

const AddPictures = (props) => {
    const { t } = useTranslation();
    const style = useSelector(state => state.style);

    return (
        <Container key={props.res.id} style={{marginTop: "1rem", marginBottom: "0.5rem"}}>
            <Input
                style={{ display: "none" }}
                inputProps={{accept : "image/*"}}
                id={props.res.id}
                name={'v_' + props.res.id}
                required={props.required}
                disabled={props.disabled}
                type="file"
                //value={props.formState['v_' + props.res.id] ? props.formState['v_' + props.res.id] : ""}
                onChange={props.handleChange}
                fullWidth
            />

            <Row>
                <span style={{color: props.formState['v_' + props.res.id+"error"] ? "red" : "rgba(0, 0, 0, 0.54)", marginLeft: "1rem", marginBottom: "0.5rem"}}>{props.label}</span>
            </Row>

            <Row>
                <label htmlFor={props.res.id}>
                    <Fab variant="extended" component="span" style={{color: props.disabled ? "rgba(0, 0, 0, 0.54)" : style.button.backgroundColor, margin: "10", backgroundColor: style.button.color, borderRadius: "6px", boxShadow: "none"}}>
                        <AddPhotoAlternateIcon style={{marginRight: "8px"}}/>
                        {t('g_btn_import_picture')}
                    </Fab>
                </label>

                <MediaQuery maxDeviceWidth={800}>
                    <div id="preview-new-medias" style={{display: "flex", alignItems: "center", marginTop:"0.5rem"}}>
                        {Array.isArray(props.Files) ?
                            props.Files.map((file, key) => {
                                    return <NewMedias key={key} title={file.name} resetState={props.resetState} res={props.res} />
                                }
                            ) :
                            <span style={{margin: "0 0.5rem 0.5rem 0.5rem", display: "flex", alignItems: "center"}} >
                            {props.Files ? (
                                <React.Fragment>
                                    {props.Files.name} <DeleteButtonImg {...props} />
                                </React.Fragment>
                            ) : ""}
                        </span>
                        }
                    </div>
                </MediaQuery>

                <MediaQuery minDeviceWidth={800}>
                    <div id="preview-new-medias" style={{display: "flex", alignItems: "center"}}>
                        {Array.isArray(props.Files) ?
                            props.Files.map((file, key) => {
                                    return <NewMedias key={key} title={file.name} resetState={props.resetState} res={props.res} />
                                }
                            ) :
                            <span style={{margin: "0 1vw", display: "flex", alignItems: "center"}} >
                            {props.Files ? (
                                <React.Fragment>
                                    <p style={{margin: "0 0.5rem 0 0"}}>{props.Files.name}</p> <DeleteButtonImg {...props} />
                                </React.Fragment>
                            ) : ""}
                        </span>
                        }
                    </div>
                </MediaQuery>

            </Row>
        </Container>
    )
}

const DisplayPictures = (props) => {
    let pictures = props.formState['v_' + props.res.id+'_default'].split(";")

    return (
        <Container key={props.res.id} style={{marginTop: "1rem", marginBottom: "0.5rem"}}>
            <Row>
                <span style={{color: props.formState['v_' + props.res.id+"error"] ? "red" : "rgba(0, 0, 0, 0.54)", marginLeft: "1rem", marginBottom: "0.5rem"}}>{props.label}</span>
            </Row>
            <Row>

                {pictures.map((picture, key) => {
                    return (
                        <React.Fragment key={key}>
                            <MediaQuery maxDeviceWidth={800}>
                                <div style={{margin: "0 12px 12px 12px", display:"flex", width: "100%", justifyContent:"space-around"}}>
                                    <div style={{width: "inherit", textAlign:"center"}}>
                                        <img
                                            src={buildShapperPath(picture)}
                                            className="d-inline-block align-top"
                                            alt={buildShapperPath(picture)}
                                            style={{width: "inherit", maxWidth: "200px", borderRadius: "5px"}}
                                        />
                                    </div>

                                    <span style={{width: "3em", display: "flex", alignItems: "center", justifyContent:"center"}}>
                                        <DeleteButtonImg {...props} index={key} handleIndex={true} />
                                    </span>
                                </div>
                            </MediaQuery>

                            <MediaQuery minDeviceWidth={800} key={key}>
                                <div style={{margin: "0 12px 12px 12px", display:"flex"}}>
                                    <div style={{maxWidth: "175px", alignItems:"center", justifyContent:"center", display:"flex"}}>
                                        <img
                                            src={buildShapperPath(picture)}
                                            className="d-inline-block align-top"
                                            alt={buildShapperPath(picture)}
                                            style={{width: "100%", borderRadius: "5px"}}
                                        />
                                    </div>

                                    <span style={{width: "3em", display: "flex", alignItems: "center", justifyContent:"center"}}>
                                        <DeleteButtonImg {...props} index={key} handleIndex={true} />
                                    </span>
                                </div>
                            </MediaQuery>
                        </React.Fragment>
                    )
                })}
            </Row>
        </Container>
    )
}

const NewMedias = (props) => {
    if(props.title){
        return (
            <span style={{margin: "0 1vw", display: "flex", alignItems: "center"}} >
                <p style={{margin: "0 0.5rem 0 0"}}>{props.title}</p> <DeleteButtonImg {...props} />
            </span>
        )
    } else return ""
}

const DeleteButtonImg = (props) => {
    return (
        <FontAwesomeIcon
            icon={faTimesCircle}
            style={{cursor: "pointer"}}
            onClick={() => props.resetState(props.res.id, props.res.type, props.title, props.index, props.handleIndex)}
        />
    )
}
