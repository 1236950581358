import React, {useEffect, useState} from "react";
import {Card, CardActionArea, CardContent, CardMedia, Grid, Typography, Paper} from "@material-ui/core";
import {Col} from "react-bootstrap";
import {prettyPrice} from "../../helpers/Tools";
import { useTranslation } from 'react-i18next';
import "./Cart/Stripe/stripe.css"

/**
 * Construction du rendu d'une synthèse de commande
 */
export const OrderSummary = (props) => {
    const { t } = useTranslation();
    const [isDisplayFormResponse, displayFormResponse] = useState(false);
    let products = props.order.products;
    let order = props.order;

    useEffect(() => {
        if(order && order.form_response){
            displayFormResponse(true);
        }
    }, [order])

    return (
        <Paper className="paper" style={{backgroundColor: "white", padding: "20px", marginTop: (this.state.width <= 800) ? "20px" : "100px", width:"100%", textAlign:"left", marginBottom:(this.state.width <= 800) ? "20px" : "200px"}}>
            <Grid container
                  spacing={3}
                  alignItems="center"
                  justify="center"
            >
                {products.map((product, key) => {
                    let price_ttc = prettyPrice((product.qte*product.price_unit_ttc).toFixed(2));
                    return (
                        <Grid item xs={12} sm={3} key={key} style={{textAlign:"-webkit-center"}}>
                            <Card style={{marginTop:"15px", width:"300px", height:"200px"}}>
                                <CardActionArea style={{textAlign:"center"}}>
                                    <CardMedia
                                        component="img"
                                        alt={product.title}
                                        height="110"
                                        image={product.media_url}
                                        title={product.title}
                                        style={{objectFit:"scale-down"}}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2" style={{whiteSpace:"nowrap", overflow:"hidden", textOverflow:"ellipsis"}}>
                                            {product.title}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            {t('market_label_quantity')} {product.qte} | {price_ttc} €
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>

            <div style={{border:"1px groove", borderRadius:"5px", padding:"20px", marginTop:"50px", marginBottom:"80px"}}>
                <div className="row justify-content-md-center" >
                    <Col xs={12} sm={3}>
                        <h4>{t('market_label_price_ht_euro', {price: prettyPrice(parseFloat(order.amount_ht).toFixed(2))})}</h4>
                    </Col>
                    <Col xs={12} sm={3}>
                        <h3>{t('market_label_price_ttc_euro', {total_price: prettyPrice(parseFloat(order.amount_ttc).toFixed(2))})}</h3>
                    </Col>
                    <Col xs={12} sm={3}>
                        <h4>{t('market_label_price_tva_euro', {tva: prettyPrice((parseFloat(order.amount_ttc)-parseFloat(order.amount_ht)).toFixed(2))})}</h4>
                    </Col>
                </div>

                {isDisplayFormResponse ?
                    (
                        <div className="row justify-content-md-center" style={{marginTop:"20px"}}>
                            <Col>
                                {order.form_response.map((response, index) => {
                                    return (
                                        <div key={index}>
                                            <h6>{response.key} : {response.value}</h6>
                                        </div>
                                    );
                                })}
                            </Col>
                        </div>
                    ) : ""
                }
            </div>
        </Paper>
    );
}
