import {useTranslation} from "react-i18next";
import React from "react";
import {REGEX_URL} from "../Formulaire.functions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import Container from "reactstrap/es/Container";
import {Fab, Input} from "@material-ui/core";
import {Row} from "react-bootstrap";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import {youtubeUrl} from "../../../helpers/Tools";
import {useSelector} from "react-redux";

export const FormulaireDynamicVideo = (props) => {
    const { t } = useTranslation();
    const style = useSelector(state => state.style);

    let span_text_video;
    let isUrl_video = false;

    if (props.formState['v_' + props.res.id+'_default']) {
        if(REGEX_URL.test(props.formState['v_' + props.res.id+'_default'])){
            isUrl_video = true;
        }

        if(!isUrl_video){
            let splitArray = props.formState['v_' + props.res.id].split("\\")
            let length = splitArray.length
            span_text_video = splitArray[length - 1];
        }
    }

    if(!isUrl_video){
        return (
            <Container style={{marginTop: "1rem", marginBottom: "0.5rem"}}>
                <Input
                    style={{display: "none"}}
                    inputProps={{accept: "video/*"}}
                    id={props.res.id}
                    name={'v_' + props.res.id}
                    required={props.required}
                    disabled={props.disabled}
                    type="file"
                    //value={props.formState['v_' + props.res.id] ? props.formState['v_' + props.res.id] : ""}
                    onChange={props.handleChange}
                    fullWidth
                />
                <Row>
                    <span style={{color: props.formState['v_' + props.res.id + "error"] ? "red" : "rgba(0, 0, 0, 0.54)", marginLeft: "1rem", marginBottom: "0.5rem"}}>{props.label}</span>
                </Row>
                <Row>
                    <label htmlFor={props.res.id}>
                        <Fab variant="extended" component="span" style={{
                            color: props.disabled ? "rgba(0, 0, 0, 0.54)" : style.button.backgroundColor,
                            margin: "10",
                            backgroundColor: style.button.color,
                            borderRadius: "6px",
                            boxShadow: "none",
                        }}>
                            <VideoLibraryIcon style={{marginRight: "8px"}}/>
                            {t('g_btn_import_video')}
                        </Fab>
                    </label>

                    <span style={{
                        marginLeft: "1vw",
                        display: "flex",
                        alignItems: "center"
                    }}>{span_text_video} {props.formState['v_' + props.res.id] && (
                        <DeleteButtonImg {...props} />
                    )} </span>
                </Row>
            </Container>
        )
    } else {
        return (
            <Container key={props.res.id} style={{marginTop: "1rem", marginBottom: "0.5rem"}}>
                <Row>
                    <span style={{color: props.formState['v_' + props.res.id + "error"] ? "red" : "rgba(0, 0, 0, 0.54)", marginLeft: "1rem", marginBottom: "0.5rem"}}>{props.label}</span>
                </Row>
                <Row>
                    <iframe
                        style={{ width: "auto", maxWidth: "800px", borderRadius:"5px" }}
                        title="youtube"
                        width="560"
                        height="315"
                        src={youtubeUrl(props.formState['v_' + props.res.id+'_default'])}
                        frameBorder="0"
                        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />

                    <span style={{width: "3em", display: "flex", alignItems: "center", justifyContent:"center"}}><DeleteButtonImg {...props} index={0} handleIndex={true} /></span>
                </Row>
            </Container>
        )
    }
};

const DeleteButtonImg = (props) => {
    return (
        <FontAwesomeIcon
            icon={faTimesCircle}
            style={{cursor: "pointer"}}
            onClick={() => props.resetState(props.res.id, props.res.type, props.title, props.index, props.handleIndex)}
        />
    )
}
