import React, {useState} from "react";
import {Image} from "react-bootstrap";
import JsxParser from "react-jsx-parser";
import image_not_found from "../assets/image_not_found.png"
import {store} from "../store/store"
import OpenItem from "./OpenItem";
import Parser from "html-react-parser";
import history from "../history";
import parse from "html-react-parser";

export const regExShapperImgAppIdPrefix = /(https?:\/\/(?<remove>[0-9]*\.)(static.shapper.net|static.shapperdev.net)\S+(?:png|jpg|jpeg|gif))/igm;
export const regExShapperImg = /(https?:\/\/(|[0-9]*\.)(static.shapper.net|static.shapperdev.net)\S+(?:png|jpeg|jpg|gif))/igm;
export const regExShapperImgEndPoint = /(https?:\/\/(static.shapper.net|static.shapperdev.net)\/([0-9]*))/igm;

export function youtubeUrl(url){

    let regexYtb = /https?:\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w.-]*)(&(amp;)?[\w?=]*)?/;
    if(url.match(regexYtb)){

        let match = regexYtb.exec(url);

        return (window.location.protocol+"//www.youtube.com/embed/" + match[1] )
    }else{
        return url
    }
}
export function getQueryVariable(variable)
{
    let  query = window.location.search.substring(1);
    let vars = query.split("&");

    for (let i=0;i<vars.length;i++) {
        let pair = vars[i].split("=");
        if(pair[0] === variable) {
            return pair[1];
        }
    }
    return false;
}

/**
 * RECUPERATION ET TRANSFORMATION URL EN FORMAT VALIDE
 * @param url
 * @param getApp
 * @returns {string|*}
 */
export function buildShapperPath(url, getApp = null) {
    let app = getApp ? getApp : store.getState().application.getApp;

    if (url != null) {
        if (url.startsWith("http://") || url.startsWith("https://")) {

            //delete app id before host
            let matches = url.matchAll(regExShapperImgAppIdPrefix);
            for(const match of matches){
                url = url.replace(match.groups.remove, "")
            }

            return buildSecurePath(url, app.webParams.force_https);
        } else {

            let protocol = app.webParams.force_http === "1" && process.env.NODE_ENV === "production" ? "https:" : window.location.protocol;

            return protocol +"//"+app.host_ftp_ress + "/" + app.id + "/" + url;
        }
    } else {
        return "";
    }
}

/**
 * Force une url en https si besoin
 * @param url
 * @param force_https
 * @returns new_url
 */
export function buildSecurePath(url, force_https){
    let new_url;
    if(url && ((window.location.protocol === "https:" && url.startsWith("http://")) || ( url.startsWith("http://") && force_https === "1" && process.env.NODE_ENV === "production"))){
        new_url = url.replace('http', 'https');
    } else {
        new_url = url;
    }

    return new_url
}

/**
 * extrait endpoint d'url static pour médias
 * @param url
 * @returns {*}
 */
export function extractShapperStaticEndPoint(url){
    return url.replace(regExShapperImgEndPoint, "");
}

export function findSelectedInContents(list, parent_id, contentToRedirect){
    let selected;

    if(typeof list !== "undefined"){
        if(parent_id){
            list.some((currentItem) => selected = currentItem.id === parent_id ? currentItem : findSelectedInContents(currentItem.nodes ? currentItem.nodes : [], parent_id));
        } else {
            selected = list.find((element) => element.id === contentToRedirect)
        }
    }

    return selected;
}

/**
 * CREATION NOM DES ROUTES
 * @param url
 * @returns {*}
 */
export function urlrewriting(url) {
    url = url.toLowerCase();

    url = url.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    url = url.replace(/\s/g, "-");
    url = url.replace(/[^a-z0-9-]/g, "");
    //url = url.replace(/[-{2,}]/g, "-");

    return url;
}

/**
 * RECUPERATION PAGE PAR SON ID
 * @param data
 * @param id
 * @returns {*}
 */
export function getPageById(data, id) {
    console.log(data);
    console.log(id);
    data = getAllContents(data);
    var result = data.find(
        function(item) {
            return (item.id.toString() === id.toString());
        }
    );
    console.log(result);
    return result;
}

/**
 * RECUPERATION TITLE PAR SON ID Pour BreadCumbs
 * @param RoutePathArray
 * @param id
 * @param myTab
 * @returns {*}
 */
export function getTitleById(RoutePathArray, id, myTab) {
    if(RoutePathArray[id]){
        let title = RoutePathArray[id].title;
        let parent_id = RoutePathArray[id].parentId;

        console.log("getTitleById : " + title + " " + id + " " + parent_id);

        if(parent_id === id){
            myTab = myTab.reverse();
        }
        else {
            let Object = {};
            Object.title = title;
            Object.id = id;
            myTab.push(Object);

            getTitleById(RoutePathArray, parent_id, myTab)
        }
    }else {
        myTab = myTab.reverse();
    }
    return myTab;
}

/**
 * Récupération de tout les contents pour search
 * @param allContents
 * @returns {*}
 */
export function getAllContents(allContents) {
    for (let content of allContents){
        if (content.nodes){
            if (content.nodes.length !== 0){
                content.nodes.forEach((node) => {
                    allContents.push(node);
                })
            }
        }
    }

    return [...new Set(allContents)]
}

/**
 * Permet de choisir l'image pour category
 * @param data
 * @returns {*}
 */
export function choiceImage(data) {
    let Photo ;
    if(data.preview_url){
        Photo =  buildShapperPath(data.preview_url);
    }else if (data.banner_url){
        Photo =  buildShapperPath(data.banner_url);
    }else if (data.media_url) {
        if(data.media_url.match(/^.+\.(([pP][dD][fF]))$/)){
            Photo = image_not_found;
        }else{
            Photo = buildShapperPath(data.media_url);
        }
    }else{
        Photo = image_not_found;
    }

    return Photo;
}

/**
 * Creation ID Unique
 * @param length
 * @returns {string}
 */
export function makeId(length) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

/**
 * Récuperation Navigateur & Version
 * @returns {string}
 */
export function getBrowser(){
    let nVer = navigator.appVersion;
    let nAgt = navigator.userAgent;
    let browserName  = navigator.appName;
    let fullVersion  = ''+parseFloat(nVer);
    let majorVersion,nameOffset,verOffset,ix, needUpdate;
    const stableFirefoxMajorVersion = 90;
    const stableSafariMajorVersion = 14;
    const stableChromeMajorVersion = 92;

// In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset=nAgt.indexOf("Opera")) !== -1) {
        browserName = "Opera";
        fullVersion = nAgt.substring(verOffset+6);
        if ((verOffset=nAgt.indexOf("Version")) !== -1)
            fullVersion = nAgt.substring(verOffset+8);
    }
// In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset=nAgt.indexOf("MSIE")) !== -1) {
        browserName = "Microsoft Internet Explorer";
        fullVersion = nAgt.substring(verOffset+5);
    }
// In Chrome, the true version is after "Chrome"
    else if ((verOffset=nAgt.indexOf("Chrome")) !== -1) {
        browserName = "Chrome";
        fullVersion = nAgt.substring(verOffset+7);
    }
// In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset=nAgt.indexOf("Safari")) !== -1) {
        browserName = "Safari";
        fullVersion = nAgt.substring(verOffset+7);
        if ((verOffset=nAgt.indexOf("Version")) !== -1)
            fullVersion = nAgt.substring(verOffset+8);
    }
// In Firefox, the true version is after "Firefox"
    else if ((verOffset=nAgt.indexOf("Firefox")) !== -1) {
        browserName = "Firefox";
        fullVersion = nAgt.substring(verOffset+8);
    }
// In most other browsers, "name/version" is at the end of userAgent
    else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) < (verOffset=nAgt.lastIndexOf('/')) ) {
        browserName = nAgt.substring(nameOffset,verOffset);
        fullVersion = nAgt.substring(verOffset+1);
        if (browserName.toLowerCase() === browserName.toUpperCase()) {
            browserName = navigator.appName;
        }
    }
// trim the fullVersion string at semicolon/space if present
    if ((ix=fullVersion.indexOf(";"))!== -1)
        fullVersion=fullVersion.substring(0,ix);
    if ((ix=fullVersion.indexOf(" "))!== -1)
        fullVersion=fullVersion.substring(0,ix);

    majorVersion = parseInt(''+fullVersion,10);
    if (isNaN(majorVersion)) {
        fullVersion  = ''+parseFloat(navigator.appVersion);
        majorVersion = parseInt(navigator.appVersion,10);
    }

    if(browserName === "Firefox"){
        needUpdate = stableFirefoxMajorVersion > majorVersion;
    } else if(browserName === "Safari"){
        needUpdate = stableSafariMajorVersion > majorVersion;
    } else if(browserName === "Chrome"){
        needUpdate = stableChromeMajorVersion > majorVersion;
    } else {
        needUpdate = false;
    }

    return {
        browserName: browserName,
        fullVersion: fullVersion,
        majorVersion: majorVersion,
        needUpdate: needUpdate
    }
}

/**
 * Récuperation OS
 * @returns {null}
 */
export function getOS() {
    let userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'];

    if (macosPlatforms.indexOf(platform) !== -1) {
        return 'Mac_OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        return 'web_ios';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        return 'Windows';
    } else if (/Android/.test(userAgent)) {
        return 'web_android';
    } else if (/Linux/.test(platform)) {
        return 'Linux';
    }
}

/**
 * Formate l'affichage des prix
 * @param price
 * @returns {string}
 */
export function prettyPrice(price) {
    const state = store.getState()
    const {application} = state

    return new Intl.NumberFormat(application.language).format(price);
}

/**
 * Range tableau d'objet by title
 * @param array
 * @param key
 * @returns {*}
 */
export function sortArray(array, key){
    return (
        array.sort(function compare(a, b) {
            if (a[key] < b[key])
                return -1;
            if (a[key] > b[key] )
                return 1;
            return 0;
        })
    );
}

/**
 * Test si deux array sont identiques
 * @param array1
 * @param array2
 * @returns {boolean}
 */
export function isSame(array1, array2) {
    return !!((array1.length === array2.length) && array1.every(function (element, index) {
        return element === array2[index];
    }));
}

/**
 * Récupère variation en fonction des attributs
 * @param totalVariations
 * @param Attributs
 * @returns {{}}
 */
export function getVariationFromAttributs(totalVariations, Attributs){
    let variation_final = {};
    for (let variation of totalVariations){
        if (isSame(variation.attributValues, Attributs)){
            variation_final = variation;
            break;
        }
    }

    return variation_final;
}

/**
 * Permet de cloner un element
 * @param obj
 * @returns {*}
 */
export function clone(obj){
    let copy;
    try{
        copy = JSON.parse(JSON.stringify(obj));
    } catch(e){
        console.log("erreur clonage")
    }
    return copy;
}


/**
 *  Permet de créer des liens clickables pour le parser
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ParserComp = (props) =>  {
    return (
        <OpenItem
            contentToRedirect={props.idItemLink}
        >
            {Parser(props.nameItemLink)}
        </OpenItem>
    )
}

/**
 * Création et utilisation du parserJSX pour utiliser les différents liens créés par le client et le rendu html dans le contenu de la Page
 * @param content
 * @returns {JSX.Element}
 */
export function parserContent(content){
    let newLinkNativeBrowser = "";

    if(content){

        let regexStyles = /<style[^>]*>[\s\S]*?<\/style>/gi;
        let styles = content.match(regexStyles);

        if(styles){
            styles.forEach(style => {
                content = content.replace(style, "");
            })

            styles.forEach(style => {
                let styleElement = document.createElement('style');
                styleElement.innerHTML = style.replace(/<style[^>]*>/, "").replace(/<\/style>/, "");
                document.head.appendChild(styleElement);
            })
        }

        let shapperImgMatches = content.match(regExShapperImg);

        if(shapperImgMatches){
            shapperImgMatches.forEach(url_match => {
                content = content.replace(url_match, buildShapperPath(url_match))
            })
        }

        let regExGlobal = /<a(.*?)="(?<id>.*?)">(?<name>.*?)<\/a>/gim;
        let regExLink = /<a(.*?)="(item)(:\/\/)(?<id>.*?)">(?<name>.*?)<\/a>/gim;
        let regExNativeBrowserFinale = /<a(.*?)="(nativebrowser)(:\/\/)(?<id>.*?)">(?<name>.*?)<\/a>/im;
        let regExNativeBrowser = /nativebrowser:/gim;
        let regExNativeBrowserBlank = /<a/gim;

        let newLinkUrl = content.replace(regExLink, `<ParserComp idItemLink='$4' nameItemLink='$5'/>`); //url interne
        let link_externe_tmp = "";

        // url page externe
        let allLink = newLinkUrl.match(regExGlobal);

        if (allLink){
            allLink.forEach(link => {
                if (link.match(regExNativeBrowser)){
                    link_externe_tmp = link.replace(regExNativeBrowserBlank, '<a target="_blank"');
                    link_externe_tmp = link_externe_tmp.replace(regExNativeBrowser, "");
                    newLinkUrl = newLinkUrl.replace(regExNativeBrowserFinale, link_externe_tmp)
                    newLinkNativeBrowser = newLinkUrl;
                } else {
                    newLinkNativeBrowser = newLinkUrl;
                }
            });
        } else {
            newLinkNativeBrowser = newLinkUrl;
        }
    }

    newLinkNativeBrowser = getValueFromVariable(newLinkNativeBrowser, store.getState().variableStorage);

    newLinkNativeBrowser = newLinkNativeBrowser.replace('href=""', "");
    newLinkNativeBrowser = newLinkNativeBrowser.replace('href="/"', "");
    newLinkNativeBrowser = newLinkNativeBrowser.replace('href="//"', "");

    console.log(newLinkNativeBrowser);
    //var parse = require('html-react-parser');
    //return parse(newLinkNativeBrowser);

    return <JsxParser components={{ ParserComp }} jsx={newLinkNativeBrowser} />
}

/**
 * calcule le nombre de places restantes pour un event
 * @param max_participation
 * @param actual_participation
 * @returns {number}
 */
export function placesForEvent(max_participation, actual_participation){
    return  parseInt(max_participation) - actual_participation.length;
}

/**
 *  Formate l'objet event pour le planning
 * @param event
 * @param planning
 * @returns {{}}
 */
export function createEventObject(event, planning){
    let new_event = {};

    new_event.title = event.title;
    new_event.id = event.id;
    new_event.startDate = new Date(event.startDate+" "+event.startTime)
    new_event.endDate = new Date(event.endDate+" "+event.endTime)
    new_event.planning = planning.title;
    new_event.availability = event.event_availability;
    new_event.places = placesForEvent(event.max_participation, event.participants);
    new_event.organizer_mobinaute_id = event.organizer_mobinaute_id;

    //data pour update
    new_event.description = event.description;
    new_event.startDateString = event.startDate;
    new_event.endDateString = event.endDate;
    new_event.startTimeString = event.startTime;
    new_event.endTimeString = event.endTime;
    new_event.address_city = event.address_city;
    new_event.address_country = event.address_country;
    new_event.address_street1 = event.address_street1;
    new_event.address_street2 = event.address_street2;
    new_event.address_zip = event.address_zip;

    return new_event
}

/**
 * Composant image custom permettant de retourner un fallback si l'image principale ne se charge pas
 * @param src
 * @param alt
 * @param fallback
 * @param type
 * @returns {*|JSX.Element}
 * @constructor
 */
export const ImageCustom = ({ src, alt, fallback, type }) => {
    const [error, setError] = useState(false);

    const onError = () => {
        setError(true);
    };

    if(type === "page"){
        return error ? fallback : <Image src={buildShapperPath(src)} alt={alt} style={{height:'auto',width: "100%"}} onError={onError} />;
    } else {
        return error ? fallback : <img src={buildShapperPath(src)} alt={alt} style={{width: "100%"}} onError={onError} />;
    }
};

/**
 *  test si string est parsable en json ou non
 * @param str
 * @returns {any|boolean}
 */
export function isJsonParsable(str) {
    try {
        return (JSON.parse(str) && !!str);
    } catch (e) {
        return false;
    }
}

/**
 * test si obj est vide
 * @param obj
 * @returns {boolean}
 */
export function isNotEmpty(obj){
    return obj && Object.keys(obj).length !== 0 && obj.constructor === Object
}

export function arrayFilter(array, key, value){
    return array.reduce(function (pre, curr) { if (curr.hasOwnProperty(key) && curr[key] === value) pre.push(curr); return pre;}, [])
}

export function constructExternalLink(web_url){
    let regExWebUrl = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!]))?/;

    if(regExWebUrl.test(web_url)){
        return web_url;
    } else {
        return "http://" + web_url;
    }

}

export function constructLinkDestination(item){
    let destination_link = "https://www.google.com/maps/dir//";

    if(isNotEmpty(item)){
        if(item.address_lat && item.address_lng){
            destination_link += item.address_lat+","+item.address_lng;
        }

        if(!item.address_lat && !item.address_lng && item.address_city && item.address_street1 && item.address_zip && item.address_country){
            destination_link += item.address_street1+" "+item.address_zip+" "+item.address_city+" "+item.address_country;
        }

        return destination_link;
    } else {
        return "";
    }
}

export function isResponsive(width = null){

    let innerWidth = width;

    if(!width){
        let app = store.getState().application;

        innerWidth = app.width;
    }


    return getOS() === 'web_ios' || getOS() === 'web_android' || innerWidth <= 800;
}

export function pushHistory(path){

    let newPath = path + window.location.search;

    newPath = removeUrlParam("sharecontent", newPath);
    newPath = removeUrlParam("code", newPath);
    newPath = removeUrlParam("access_token", newPath);
    history.push(newPath);
}

function removeUrlParam(key, sourceURL) {
    let rtn = sourceURL.split("?")[0],
        param,
        params_arr = [],
        queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
        params_arr = queryString.split("&");
        for (let i = params_arr.length - 1; i >= 0; i -= 1) {
            param = params_arr[i].split("=")[0];
            if (param === key) {
                params_arr.splice(i, 1);
            }
        }
        rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
}

export function dateToLocaleString(date, translation) {

    let timestamp = date.split(" ").join("T"); //required for Safari

    let localeDate = new Date(timestamp);

    const dateNow = new Date();

    if(localeDate.getFullYear() === dateNow.getFullYear() && localeDate.getMonth() === dateNow.getMonth() && localeDate.getDate() === dateNow.getDate()){
        const hours = localeDate.getHours() > 9 ? localeDate.getHours() : "0"+localeDate.getHours();
        const minutes = localeDate.getMinutes() > 9 ? localeDate.getMinutes() : "0"+localeDate.getMinutes();

        localeDate = translation('mrkt_announce_label_today') + ", "+ hours +':'+ minutes;

    } else {
        const locale = store.getState().application.language
        const options = { month: 'short', day: 'numeric', hour:'numeric', minute:"numeric" }

        localeDate = localeDate.toLocaleDateString(locale, options)
    }

    return localeDate;
}


export function getValueFromVariable(value, variableStorage){

    let regEx = /{([^}]*)}/gi;
    let matches = value.matchAll(regEx)
    let new_value = value;

    for(let match of matches){
        if(variableStorage[match[1]]){
            new_value = new_value.replaceAll(match[0], variableStorage[match[1]])
        } else {
            new_value = "";
        }
    }

    return new_value;
}
