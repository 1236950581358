import {arrayFilter, buildShapperPath} from "../../helpers/Tools";
import React from "react";
import {Button, CssBaseline, Badge} from "@material-ui/core";
import { Link } from "react-router-dom";
import {Navbar, NavbarToggler, Nav, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,} from "reactstrap";
import {connect} from "react-redux";
import OpenItem from "../../helpers/OpenItem";
import {bindActionCreators} from "redux";
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import {filter} from "./NavBar.functions"
import {NavBarIdentification} from "./NavBarIdentification/NavBar.Identification"
import "./NavBar.css"

class NavBar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            width: window.innerWidth,
            height: window.innerHeight,
            navElements: [],
            identCompElement: null,
            elementInNavBar: [],
            elementInDropDown: []
        };
    }

    // Mise à jour des dimensions en fonction de celles de la fenêtre
    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    render() {

        if(!this.props.application.getApp.webParams){
            return <h1> Activer le mode Web</h1>
        }

        // Creation copie de result pour éviter la suppression des items sur menu à chaque refresh lors du slice
        let navElements = arrayFilter(this.props.result, 'is_visible', '1');
        let identCompElement = null;

        let modeLeftStyle = {
            marginLeft: "20px",
            textAlign: "center"
        }
        let modeRightStyle = {
            marginRight: "20px",
            textAlign: "center"
        }
        let modeCenterStyle = {
            marginRight: "auto",
            marginLeft: "auto",
            textAlign: "center"
        }

        var navItemStyle = modeCenterStyle;
        if(this.props.application.getApp.webParams.align_nav === "right"){
            navItemStyle = modeRightStyle;
        }
        else if(this.props.application.getApp.webParams.align_nav === "left"){
            navItemStyle = modeLeftStyle;
        }

       if(this.props.routes.PathIdentification){
           filter(navElements, function (value, prop, subject) {
               if( prop === "type" && value === "identification"){

                   identCompElement = subject
               }
           })

           if(identCompElement && !identCompElement.parent_id){
               navElements.splice(navElements.findIndex((element) => element.id === identCompElement.id), 1);
           }
       }

        let maxItemNavBar = parseInt((this.state.width - 450)/170, 10);

        let elementInNavBar = navElements.slice(0, maxItemNavBar);
        let elementInDropDown = navElements.slice(maxItemNavBar);

        // Création Du DropDown Menu + Condition Affichage
        let dropdownMenu;

        // init dropdown menu
        if (navElements.length > elementInNavBar.length) {
            dropdownMenu = (
                <UncontrolledDropdown nav inNavbar style={navItemStyle}>
                    <Button style={{backgroundColor: 'transparent'}}>
                        <p style={{
                            marginTop:"1rem",
                            marginBottom:"1rem",
                            maxWidth: "150px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            color: this.props.styles.menu.text_color,
                            fontSize: "medium",
                            textTransform: "none",
                            fontWeight: "bold",
                            textDecoration: "none"}}>
                            <DropdownToggle
                                nav
                                caret
                                style={{
                                    color: this.props.styles.menu.text_color,
                                    padding: "0",
                                    outlineStyle: "none",
                                    textTransform: "none",
                                }}
                                onMouseEnter={(e) => e.target.style.color = this.props.styles.menu.text_color_selected}
                                onMouseLeave={(e) => e.target.style.color = this.props.styles.menu.text_color}
                            >
                                { this.props.t('g_label_navbar_other')}
                            </DropdownToggle>
                        </p>
                    </Button>
                    <DropdownMenu right style={{ textDecoration: "none", overflowY: 'scroll', maxHeight:this.state.height/2}}>
                        {elementInDropDown.map((res, key) => {
                            let styleParagraph = {marginTop:"1rem", marginBottom:"1rem", color:this.props.styles.menu.text_color}

                            if (res.type === "cart"){
                                return (
                                    <OpenItem
                                        contentToRedirect={res.id}
                                        key={key}
                                        isFor={"nav"}
                                    >
                                        <DropdownItem
                                            style={{ background: "white", textDecoration: "none" }}
                                        >
                                            <Badge badgeContent={this.props.products.length} color="error">
                                                <p style={styleParagraph}
                                                   onMouseEnter={(e) => e.target.style.color = this.props.styles.menu.text_color_selected}
                                                   onMouseLeave={(e) => e.target.style.color = this.props.styles.menu.text_color}
                                                >
                                                    {res.title}
                                                </p>
                                            </Badge>
                                        </DropdownItem>
                                    </OpenItem>
                                );
                            } else {
                                return (
                                    <OpenItem
                                        contentToRedirect={res.id}
                                        key={key}
                                        isFor={"nav"}
                                    >
                                        <DropdownItem
                                            style={{ background: "white", textDecoration: "none" }}
                                        >
                                            <p style={styleParagraph}
                                               onMouseEnter={(e) => e.target.style.color = this.props.styles.menu.text_color_selected}
                                               onMouseLeave={(e) => e.target.style.color = this.props.styles.menu.text_color}
                                            >
                                                {res.title}
                                            </p>
                                        </DropdownItem>
                                    </OpenItem>
                                );
                            }
                        })}
                    </DropdownMenu>
                </UncontrolledDropdown>
            );
        }

        let header = "";
        let nav_style;

        if(this.props.application.getApp.webParams.header_url){
            header = (
                <div style={{ width: "100%"}} id="navbar-header">
                    <img
                        src={buildShapperPath(this.props.application.getApp.webParams.header_url)}
                        style={{ width: "100%", height: "100%", objectFit: "cover", maxHeight: "165 px", overflow: "hidden" }}
                        alt="header"
                    />
                </div>
            );

            nav_style = "withHeader";
        } else if (!this.props.application.getApp.webParams.header_url && parseInt(this.props.application.getApp.has_market) === 1){
            nav_style = "withoutHeader";
        } else {
            nav_style = "withHeader";
        }

        return (
            <React.Fragment>
                <CssBaseline />

                {header}

                <Navbar
                    expand="md"
                    className={nav_style}
                    id="navbar"
                    color="white"
                >

                    {this.props.application.getApp.webParams.logo_url && (
                        <Link to={"/"}>
                            <img
                                src={buildShapperPath(this.props.application.getApp.webParams.logo_url)}
                                width="50"
                                height="50"
                                className="d-inline-block align-top"
                                alt="logo"
                                style={{ marginLeft: "35px", marginRight: "15px", marginTop: "2px", marginBottom: "2px" }}
                            />
                        </Link>
                    )}

                    <NavbarToggler />

                    <Nav  style={{ flexWrap: "wrap", flexDirection: "row", width: "100%", justifyContent:"space-between", marginLeft:"70px"}} navbar>
                        {elementInNavBar.map((res, key) => {
                            if (res.title) {

                                if (res.type === 'cart'){
                                    return (
                                        <NavItem key={key} style={navItemStyle}>
                                            <OpenItem
                                                key={key}
                                                contentToRedirect={res.id}
                                                isFor={"nav"}
                                            >
                                                <Button style={{backgroundColor: 'transparent'}}>
                                                        <Badge badgeContent={this.props.products.length} color="error">
                                                            <p style={{marginTop:"1rem", marginBottom:"1rem", marginLeft:"25px", marginRight:"25px", color: this.props.styles.menu.text_color,
                                                                fontSize: "medium",
                                                                textTransform: "none",
                                                                fontWeight: "bold",
                                                                textDecoration: "none"}}
                                                               onMouseEnter={(e) => e.target.style.color = this.props.styles.menu.text_color_selected}
                                                               onMouseLeave={(e) => e.target.style.color = this.props.styles.menu.text_color}
                                                            >
                                                                {res.title}
                                                            </p>
                                                        </Badge>
                                                </Button>
                                            </OpenItem>
                                        </NavItem>
                                    );
                                }
                                else {
                                    return (
                                        <NavItem key={key} style={{
                                            ...navItemStyle, // Ajout des styles de base
                                            ...(key === 0 && this.props.application.getApp.webParams.align_nav === "right" ? { marginLeft: "auto" } : { marginRight: "20px" }) // Styles conditionnels
                                        }}>
                                            <OpenItem
                                                key={key}
                                                contentToRedirect={res.id}
                                                isFor={"nav"}
                                            >
                                                <Button style={{backgroundColor: 'transparent'}}>

                                                        <p style={{
                                                            marginTop:"1rem",
                                                            marginBottom:"1rem",
                                                            maxWidth: "150px",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "nowrap",
                                                            color: this.props.styles.menu.text_color,
                                                            fontSize: "medium",
                                                            textTransform: "none",
                                                            fontWeight: "bold",
                                                            textDecoration: "none"
                                                        }}
                                                           onMouseEnter={(e) => e.target.style.color = this.props.styles.menu.text_color_selected}
                                                           onMouseLeave={(e) => e.target.style.color = this.props.styles.menu.text_color}
                                                        >
                                                            {res.title}
                                                        </p>
                                                </Button>
                                            </OpenItem>
                                        </NavItem>
                                    );
                                }
                            } else return "";
                        })}
                        {dropdownMenu}

                        {identCompElement && (
                            <NavBarIdentification
                                alignNav={this.props.application.getApp.webParams.align_nav}
                                connected={this.props.connected}
                                identCompElement={identCompElement}
                                width={this.state.width}
                                height={this.state.height}
                            />
                        )}
                    </Nav>
                </Navbar>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    products: state.cart.items,
    application: state.application,
    styles: state.style,
    routes: state.routes
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {},
        dispatch
    );

export default compose( withTranslation(), connect(mapStateToProps, mapDispatchToProps))(NavBar);
