import React from "react";
import {calcAdaptiveVue, typeUrl} from "./GraphicalView.functions";
import {OneDisplay} from "./OneDisplay/OneDisplay";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Carousel} from "./Carousel/Carousel";
import {buildShapperPath, clone} from "../../helpers/Tools";
import {handleConnector} from "../Category/Category.functions";
import {requestGetConnectorsData} from "../../actions";
import {ShapperLoader} from "../../components/ShapperLoader/ShapperLoader";

class Graph extends React.Component {

    constructor(props) {
        super(props);

        if (this.props.dataPage.connector_id) {
            this.state = {
                loading: true
            };
        }
        else {
            this.state = {
                loading: false
            };
        }

    }

    async componentDidMount() {
        window.scrollTo(0,0);
        if(this.props.dataPage.connector_id){
            if(this.props.connectors.success){
                handleConnector(this.props.connectors.result, this.props.dataPage, this.props.variableStorage).then(isLoading => {
                    this.setState({loading: isLoading});
                })
            }
        }
    }

    render() {

        const {dataPage} = this.props;

        console.log(" %c ////////CONTENU-GRAPH////////", 'background: #222; color: #bada55');
        console.log(dataPage.title);

        let json_content = JSON.parse(dataPage.content_tmp);
        let queries = {};
        var json_content_updated = [];

        let landscape_sort = Object.entries(json_content.landscape).sort(function(a,b) {
            return a[1].width - b[1].width;
        }); // trie du tab pour que le plus petit soit traité en premier
        let portrait_sort = Object.entries(json_content.portrait).sort(function(a,b) {
            return b[1].width - a[1].width;
        }); // trie du tab pour que le plus grand soit traité en premier

        portrait_sort.forEach((content) => {
            json_content_updated.push(content[1]);
        })
        landscape_sort.forEach((content) => {
            json_content_updated.push(content[1]);
        })

        //Création des QUERIES LANDSCAPE
        json_content_updated.forEach((res, i) => {

            let orientation = "";

            if (
                (Object.entries(json_content.portrait).length === 0 && Object.entries(json_content.landscape).length === 0)
                ||
                ((Object.entries(json_content.portrait).length + Object.entries(json_content.landscape).length) === 1)
            ) {
                orientation = "screen";
                queries[i] = orientation;
            } else if (json_content_updated[i].display_type === "PORTRAIT") {
                orientation = "(orientation: portrait)";
            } else if (json_content_updated[i].display_type === "LANDSCAPE") {
                orientation = "(orientation: landscape)";
            }

            var minWidth = "";
            var maxWidth = "";
            if((typeof json_content_updated[i+1] != "undefined") && (json_content_updated[i+1].display_type === json_content_updated[i].display_type)){
                let calc = (parseInt(json_content_updated[i].width) + parseInt(json_content_updated[i+1].width))/2;
                maxWidth = " and (max-width: " + calc + "px)";
            }
            if((typeof json_content_updated[i-1] != "undefined") && (json_content_updated[i-1].display_type === json_content_updated[i].display_type)){
                let calc = (parseInt(json_content_updated[i].width) + parseInt(json_content_updated[i-1].width))/2;
                minWidth = " and (min-width:" + calc + "px)";
            }
            queries[i] = orientation + minWidth + maxWidth;
        });

        const TabMatch = {};

        //Création des queries
        Object.entries(queries).forEach(
            (res, key) => (
                TabMatch[res[0]] = window.matchMedia(res[1]).matches
            )
        );

        console.log(queries);
        console.log(TabMatch);
        if (this.state.loading) {
            return <ShapperLoader inner/>
        }
        else {
            return (
                <React.Fragment>
                    {Object.entries(json_content_updated).map(([key, jsongraph], i) => {

                        if (jsongraph !== null) {

                            let widthAdaptive = jsongraph.horizontal_display === "adjust";
                            let heightAdaptive = jsongraph.vertical_display === "adjust";

                            let width;
                            let height;

                            if (widthAdaptive) {

                                let max_width = this.props.application.getApp.webParams.content_max_width;

                                width = (max_width && this.props.application.innerWidth >= max_width) ? parseFloat(max_width) : this.props.application.innerWidth;
                            } else {
                                width = jsongraph.width + "px";
                            }

                            if (heightAdaptive) {
                                height = this.props.application.innerHeight;
                            } else {
                                height = jsongraph.height + "px";
                            }

                            let containerStyle = {
                                margin: "0 auto",
                                width: width,
                                height: height,
                                position: "sticky",
                                backgroundColor: jsongraph.bg_color,
                            }

                            /*if(jsongraph.horizontal_display_alignment === "center"){
                                containerStyle = {
                                    ...containerStyle,
                                    position: "absolute",
                                    left: "50%",
                                    transform: "translateX(-50%)"
                                }
                            }

                            if(jsongraph.vertical_display_alignment === "center"){
                                containerStyle = {
                                    ...containerStyle,
                                    position: "absolute",
                                    top: "50%",
                                    transform: "translateY(-50%)"
                                }
                            }*/

                            if (jsongraph) {
                                return (
                                    TabMatch[key] && (
                                        <div
                                            key={key}
                                            style={containerStyle}
                                            className="graphvg"
                                        >

                                            {jsongraph.zones.map((res, key) => {
                                                if (res.type === "one_display") {
                                                    let myWidth = calcAdaptiveVue(res.width, jsongraph.width, width, widthAdaptive)
                                                    let myHeight = calcAdaptiveVue(res.height, jsongraph.height, height, heightAdaptive)
                                                    let myX = calcAdaptiveVue(res.position_x, jsongraph.width, width, widthAdaptive)
                                                    let myY = calcAdaptiveVue(res.position_y, jsongraph.height, height, heightAdaptive)
                                                    return (
                                                        <OneDisplay
                                                            key={key}
                                                            src={buildShapperPath(res.image)}
                                                            x={myX ? myX : 0}
                                                            y={myY ? myY : 0}
                                                            width={myWidth ? myWidth : 0}
                                                            height={myHeight ? myHeight : 0}
                                                            link={res.link}
                                                            typeLink={typeUrl(res.link)}
                                                            data={res}
                                                            style={this.props.styles}
                                                            parentId={this.props.pageId}
                                                            widthAdaptive={widthAdaptive}
                                                            heightAdaptive={heightAdaptive}
                                                        />
                                                    );
                                                } else if (res.type === "slider_horizontal") {
                                                    return (
                                                        <Carousel
                                                            key={key}
                                                            x={calcAdaptiveVue(res.position_x, jsongraph.width, width, widthAdaptive)}
                                                            y={calcAdaptiveVue(res.position_y, jsongraph.height, height, heightAdaptive)}
                                                            width={calcAdaptiveVue(res.width, jsongraph.width, width, widthAdaptive)}
                                                            height={calcAdaptiveVue(res.height, jsongraph.height, height, heightAdaptive)}
                                                            data={res}
                                                            allData={this.props.allData}
                                                            parentId={this.props.pageId}
                                                        />
                                                    );

                                                } else return "";
                                            })}
                                        </div>
                                    )
                                );
                            } else return "";
                        } else return "";
                    })}
                </React.Fragment>
            );
        }
    }
}

const mapStateToProps = (state) => ({
    connectors: state.connectors,
    styles: state.style,
    application: state.application,
    routes: state.routes
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            requestGetConnectorsData
        },
        dispatch
    );


export default connect(mapStateToProps, mapDispatchToProps)(Graph);
