import {getOS} from "../../../helpers/Tools";
import {logout} from "../NavBar.functions"
import {Button} from "@material-ui/core";
import { NavItem} from "reactstrap";
import OpenItem from "../../../helpers/OpenItem";
import React from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

export const NavBarIdentificationConnected = (props) => {
    const style = useSelector(state => state.style);
    const { t } = useTranslation();

    if (getOS() === 'web_ios' || getOS() === 'web_android' || props.width <= 800 ){
        // Mode Portrait
        return (
            <Button style={{ display: "inherit" }} onClick={props.closeMenu} >
                <div className="fa fa-user" style={{color: style.menu.icon_color, marginRight: "3px", fontSize: style.menu.fontSize+"px", width: style.menu.fontSize+"px"}}/>
                <OpenItem
                    contentToRedirect={props.identCompElement.id}
                    isFor={"navResp"}
                >
                    {props.identCompElement.subtitle}
                </OpenItem>
            </Button>
        );
    } else {
        // Mode Paysage
        let modeLeftStyle = {
            listStyle: "none",
            textAlign: "center",
            marginLeft: "auto",
            marginRight: "0px",
        }
        let modeRightStyle = {
            listStyle: "none",
            textAlign: "center",
            marginLeft: "0px",
            marginRight: "0px",
        }
        let modeCenterStyle = {
            listStyle: "none",
            textAlign: "center",
            marginLeft: "auto",
            marginRight: "0px",
        }

        var navItemStyle = modeCenterStyle;
        if(props.alignNav === "right"){
            navItemStyle = modeRightStyle;
        }
        else if(props.alignNav === "left"){
            navItemStyle = modeLeftStyle;
        }

        return (
            <React.Fragment>
                <NavItem style={navItemStyle}>
                    <Button style={{backgroundColor: 'transparent'}}>
                        <OpenItem
                            contentToRedirect={props.identCompElement.id}
                            isFor={"navIdentLogged"}
                        >
                            <p style={{marginTop:"1rem", marginBottom:"1rem",
                                fontSize: "medium",
                                textTransform: "none",
                                fontWeight: "bold",
                                textDecoration: "none"}}
                               onMouseEnter={(e) => e.target.style.color = style.menu.text_color_selected}
                               onMouseLeave={(e) => e.target.style.color = style.menu.text_color}
                            >
                                {props.identCompElement.subtitle}
                            </p>
                        </OpenItem>
                    </Button>
                </NavItem>

                <NavItem style={{ listStyle: "none", textAlign: "center", marginleft:"auto" }}>
                    <Button onClick={logout} style={{ color: style.menu.text_color_selected, textDecoration: "none", backgroundColor: 'transparent', height:"100%"}}>
                        <p style={{marginTop:"1rem", marginBottom:"1rem",
                            fontSize: "medium",
                            textTransform: "none",
                            fontWeight: "bold",
                            marginRight: "auto",
                            textDecoration: "none"}}
                           onMouseEnter={(e) => e.target.style.color = style.menu.text_color}
                           onMouseLeave={(e) => e.target.style.color = style.menu.text_color_selected}
                        >
                            {t('profile_btn_logout')}
                        </p>
                    </Button>
                </NavItem>
            </React.Fragment>
        );
    }
}
