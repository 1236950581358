import React  from "react";
import {Typography} from "@material-ui/core";

export const FormulaireTitle = (props) => {
    return (
        <Typography component="h1" variant="h5" style={{textAlign: "center", fontWeight: "bold"}}>
            {props.title}
        </Typography>
    );
}
