import React from "react";
import "../Signature/sigCanvas.css";
import TextField from "@material-ui/core/TextField/TextField";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import {Checkbox, FormControl, FormControlLabel, MenuItem, Typography, withStyles} from "@material-ui/core";
import {checkDisabled, conditionalScript} from "../Formulaire.functions";
import Container from "reactstrap/es/Container";
import {Row} from "react-bootstrap";
import {Signature} from "../Signature/Signature";
import {FormulaireDynamicVideo} from "./Formulaire.Dynamic.Video";
import {FormulaireDynamicPicture} from "./Formulaire.Dynamic.Picture";
import {FormulaireDynamicFile} from "./Formulaire.Dynamic.File";
import {FormulaireDynamicAddress} from "./Formulaire.Dynamic.Address";
import {FormulaireDynamicTags} from "../Tags/Formulaire.Dynamic.Tags";
import {parserContent} from "../../../helpers/Tools";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from 'draft-js';
import { stateFromHTML } from "draft-js-import-html"; // Utilitaire pour importer du HTML
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from 'draft-convert';

class FormulaireDynamic extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            editorsState: [],
        };
    }

    onEditorStateChange: Function = (name) => (editorState) => {
        /*this.setState({
            editorState,
        });*/


        this.setState(prevState => ({
            editorsState: {
                ...prevState.editorsState,
                [name.name]: editorState,
            }
        }));


        var htmlvalue = convertToHTML(editorState.getCurrentContent());
        console.log(htmlvalue);
        this.props.handleChange({target: {name: name.name, value: htmlvalue}});
    };


    render() {

        let page = 0;
        let display = true;

        //Customisation de la couleur d'une checkbox
        const CustomCheckbox = withStyles({
            root: {
                color: this.props.styles.button.backgroundColor,
                "&$checked": {
                    color: this.props.styles.button.backgroundColor
                }
            },
            checked: {}
        })((props) => <Checkbox color="default" {...props} />);

        return (
            <React.Fragment>
                {this.props.Form.map((res) => {
                    let required = parseInt(res.mandatory) === 1;
                    let label = this.props.formState['v_' + res.id+"error"] ? this.props.formState['v_' + res.id+"error"] : res.label;
                    let error = !!this.props.formState['v_' + res.id + "error"];

                    let disabled = checkDisabled(res, this.props.Files);
                    let inputProps = {shapper_type: res.type}

                    if(res.visibility === "hidden"){
                        display = false
                    } else {
                        if(res.conditional_script !== null){
                            display = !!conditionalScript(this.props.idName, res.conditional_script);
                        } else {
                            display = true
                        }
                    }

                    if(display){
                        switch (res.type){
                            case 'text':
                            case 'js_output':
                                return (
                                    <TextField
                                        noValidate
                                        required={required}
                                        disabled={disabled}
                                        key={res.id}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id={res.id}
                                        name={'v_' + res.id}
                                        type="text"
                                        placeholder={res.placeholder_message}
                                        value={this.props.formState['v_' + res.id] ? this.props.formState['v_' + res.id] : ""}
                                        onChange={this.props.handleChange}
                                        error={error}
                                        label={label}
                                        inputProps={inputProps}
                                    />
                                )
                            case 'Long_text':
                                return (
                                    <TextField
                                        key={res.id}
                                        variant="outlined"
                                        margin="normal"
                                        required={required}
                                        disabled={disabled}
                                        fullWidth
                                        multiline
                                        rows={3}
                                        rowsMax={4}
                                        id={res.id}
                                        name={'v_' + res.id}
                                        type="text"
                                        placeholder={res.placeholder_message}
                                        value={this.props.formState['v_' + res.id] ? this.props.formState['v_' + res.id] : ""}
                                        onChange={this.props.handleChange}
                                        error={error}
                                        label={label}
                                        inputProps={inputProps}
                                    />
                                )
                            case 'numeric':
                                return (
                                    <TextField
                                        required={required}
                                        disabled={disabled}
                                        key={res.id}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id={res.id}
                                        name={'v_' + res.id}
                                        type="number"
                                        placeholder={res.placeholder_message}
                                        value={this.props.formState['v_' + res.id] ? this.props.formState['v_' + res.id] : ""}
                                        onChange={this.props.handleChange}
                                        error={error}
                                        label={label}
                                        inputProps={{ min: res.min_value, max: res.max_value, ...inputProps }}
                                    />
                                )
                            case 'integer':
                                return (
                                    <TextField
                                        key={res.id}
                                        variant="outlined"
                                        margin="normal"
                                        required={required}
                                        disabled={disabled}
                                        fullWidth
                                        id={res.id}
                                        name={'v_' + res.id}
                                        type="number"
                                        placeholder={res.placeholder_message}
                                        value={this.props.formState['v_' + res.id] ? this.props.formState['v_' + res.id] : ""}
                                        onChange={this.props.handleChange}
                                        error={error}
                                        label={label}
                                        inputProps={{ min: res.min_value, max: res.max_value, step: "1",...inputProps }}
                                    />
                                )
                            case 'List_Multi':
                            case 'list_multi_btn':
                                if (res.list_value){
                                    return (
                                        <TextField
                                            select
                                            name={'v_' + res.id}
                                            id={'v_' + res.id}
                                            variant="outlined"
                                            label={label}
                                            key={res.id}
                                            fullWidth
                                            error={error}
                                            margin="normal"
                                            required={required}
                                            disabled={disabled}
                                            inputProps={inputProps}
                                            SelectProps={{
                                                multiple: true,
                                                value: this.props.formState['v_' + res.id] ? this.props.formState['v_' + res.id] : [],
                                                onChange: this.props.handleChange,
                                                type:"select-multiple"
                                            }}
                                        >
                                            {res.list_value.map((res, key2) =>
                                                <MenuItem key={key2} value={res.id}>{res.text_value}</MenuItem>
                                            )}
                                        </TextField>
                                    )
                                } else {
                                    return ""
                                }
                            case 'list':
                            case 'list_btn':
                                if (res.list_value){
                                    return (
                                        <TextField
                                            id={'v_' + res.id}
                                            label={label}
                                            value={this.props.formState['v_' + res.id] ? this.props.formState['v_' + res.id] : ""}
                                            select
                                            onChange={this.props.handleChange}
                                            key={res.id}
                                            fullWidth
                                            error={error}
                                            variant="outlined"
                                            margin="normal"
                                            required={required}
                                            disabled={disabled}
                                            name={'v_' + res.id}
                                            inputProps={inputProps}
                                        >
                                            <MenuItem value={""}>{"-- Sélectionnez un élément --"}</MenuItem>
                                            {res.list_value.map((res, key3) =>
                                                <MenuItem key={key3} value={res.id}>{res.text_value}</MenuItem>
                                            )}
                                        </TextField>
                                    )
                                } else {
                                    return ""
                                }
                            case 'URL':
                                return (
                                    <TextField
                                        key={res.id}
                                        variant="outlined"
                                        margin="normal"
                                        required={required}
                                        disabled={disabled}
                                        fullWidth
                                        id={res.id}
                                        name={'v_' + res.id}
                                        type="url"
                                        placeholder={res.placeholder_message}
                                        value={this.props.formState['v_' + res.id] ? this.props.formState['v_' + res.id] : ""}
                                        onChange={this.props.handleChange}
                                        error={error}
                                        label={label}
                                        inputProps={inputProps}
                                    />
                                )
                            case 'telephone':
                                return (
                                    <TextField
                                        inputProps={{ pattern : "/^(?:(?:\\+|00)33|0)\\s*[1-9](?:[\\s.-]*\\d{2}){4}$/mi", ...inputProps}}
                                        disabled={disabled}
                                        required={required}
                                        key={res.id}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id={res.id}
                                        name={'v_' + res.id}
                                        type="tel"
                                        placeholder={res.placeholder_message}
                                        value={this.props.formState['v_' + res.id] ? this.props.formState['v_' + res.id] : ""}
                                        onChange={this.props.handleChange}
                                        error={error}
                                        label={label}
                                    />
                                )
                            case 'mail':
                                return (
                                    <TextField
                                        required={required}
                                        disabled={disabled}
                                        key={res.id}
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        id={res.id}
                                        name={'v_' + res.id}
                                        type="email"
                                        placeholder={res.placeholder_message}
                                        value={this.props.formState['v_' + res.id] ? this.props.formState['v_' + res.id] : ""}
                                        onChange={this.props.handleChange}
                                        error={error}
                                        label={label}
                                        inputProps={inputProps}
                                    />
                                )
                            case 'Time':
                                return (
                                    <TextField
                                        key={res.id}
                                        variant="outlined"
                                        margin="normal"
                                        required={required}
                                        disabled={disabled}
                                        fullWidth
                                        id={res.id}
                                        name={'v_' + res.id}
                                        type="time"
                                        value={this.props.formState['v_' + res.id] ? this.props.formState['v_' + res.id] : ""}
                                        onChange={this.props.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            step: 300, // 5 min
                                            ...inputProps
                                        }}
                                        error={error}
                                        label={label}
                                    />
                                )
                            case 'Date_Time':
                                return (
                                    <TextField
                                        key={res.id}
                                        variant="outlined"
                                        margin="normal"
                                        required={required}
                                        disabled={disabled}
                                        fullWidth
                                        id={res.id}
                                        name={'v_' + res.id}
                                        type="datetime-local"
                                        value={this.props.formState['v_' + res.id] ? this.props.formState['v_' + res.id] : ""}
                                        onChange={this.props.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            ...inputProps
                                        }}
                                        error={error}
                                        label={label}
                                    />
                                )
                            case 'date':
                                return (
                                    <TextField
                                        key={res.id}
                                        variant="outlined"
                                        margin="normal"
                                        required={required}
                                        disabled={disabled}
                                        fullWidth
                                        id={res.id}
                                        name={'v_' + res.id}
                                        type="date"
                                        defaultValue={this.props.formState['v_' + res.id] ? this.props.formState['v_' + res.id] : ""}
                                        //value={this.props.formState['v_' + res.id] ? this.props.formState['v_' + res.id] : ""}
                                        onChange={this.props.handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                            ...inputProps
                                        }}
                                        error={error}
                                        label={label}
                                    />
                                )
                            case 'Label':
                                if(res.default_value){
                                    return (
                                        <Typography
                                            variant="h6"
                                            className="MuiFormControl-marginNormal MuiFormControl-root MuiFormControl-fullWidth"
                                            key={res.id}
                                            style={{textAlign:"center"}}
                                        >
                                            {res.default_value}
                                        </Typography>
                                    )
                                } else {
                                    return ""
                                }
                            case 'Boolean':
                                return (
                                    <div key={res.id} >
                                        <FormControl component="fieldset">
                                            <FormControlLabel
                                                control={
                                                    <CustomCheckbox
                                                        checked={this.props.formState[["v_"+res.id]] ? this.props.formState['v_' + res.id] : false}
                                                        onChange={this.props.handleChange}
                                                        name={'v_' + res.id}
                                                        disabled={disabled}
                                                    />
                                                }
                                                style={{color: error ? "#f44336" : ""}}
                                                label={res.label}
                                            />
                                        </FormControl>
                                    </div>
                                )
                            case 'illustration':
                                return (
                                    <div style={{width: "100%", textAlign:"center", margin:"8px 0"}} key={res.id}>
                                        <img
                                            src={res.default_value}
                                            className="d-inline-block align-top"
                                            alt={res.default_value}
                                            style={{width: "inherit", maxWidth: "800px"}}
                                        />
                                    </div>
                                )
                            case 'html':
                                if(res.default_value){
                                    return (
                                        <div style={{ marginTop: "20px" }}>
                                            {parserContent(res.default_value)}
                                        </div>
                                    )
                                } else {
                                    return ""
                                }
                            case 'text_html':

                                let name_text_html='v_' + res.id;
                                let editorState = EditorState.createEmpty()

                                if(res.default_value){
                                    editorState = EditorState.createWithContent(stateFromHTML(res.default_value));
                                }

                                if(this.state.editorsState['v_' + res.id]){
                                    editorState = this.state.editorsState[name_text_html];
                                }

                                return (
                                    <Container key={res.id} style={{marginTop: "1rem", marginBottom: "0.5rem"}}>
                                        <Row>
                                            <span style={{color: this.props.formState['v_' + res.id+"error"] ? "red" : "rgba(0, 0, 0, 0.54)", marginLeft: "1rem", marginBottom: "0.5rem"}}>{label}</span>
                                        </Row>
                                        <Row>
                                            <Editor
                                                editorState={editorState}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                toolbarStyle={{ border: "1px solid #ddd", backgroundColor: "#f5f5f5", marginBottom: "0px"}}
                                                wrapperStyle={{ border: "1px solid #ddd", borderRadius: "5px", width:"100%" }}
                                                editorStyle={{ height: "200px", border: "1px solid #ddd", borderRadius: "5px", width:"100%" }}
                                                disabled={disabled}
                                                name={name_text_html}
                                                id={name_text_html}
                                                required={required}
                                                onEditorStateChange={this.onEditorStateChange( {name: name_text_html} )}
                                                toolbar={{
                                                    options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link', 'history', 'colorPicker'],
                                                    inline: {
                                                        options: ['bold', 'italic', 'underline', 'strikethrough'], // Options pour "inline"
                                                        inDropdown: true,
                                                    },
                                                    list: {
                                                        options: ['unordered', 'ordered'], // Options pour "list"
                                                        inDropdown: true,
                                                    },
                                                    textAlign: {
                                                        options: ['left', 'center', 'right', 'justify'], // Options pour "textAlign"
                                                        inDropdown: false,
                                                    },
                                                    link: {
                                                        options: ['link', 'unlink'], // Options pour "link"
                                                        inDropdown: true,
                                                    },
                                                }}
                                            />
                                        </Row>
                                    </Container>
                                )
                            case 'Picture':
                                return (
                                    <FormulaireDynamicPicture
                                        formState={this.props.formState}
                                        res={res}
                                        resetState={this.props.resetState}
                                        required={required}
                                        disabled={disabled}
                                        handleChange={this.props.handleChange}
                                        Files={this.props.Files[res.id]}
                                        label={label}
                                        key={res.id}
                                    />
                                )
                            case 'video':
                                return (
                                    <FormulaireDynamicVideo
                                        formState={this.props.formState}
                                        res={res}
                                        resetState={this.props.resetState}
                                        required={required}
                                        disabled={disabled}
                                        handleChange={this.props.handleChange}
                                        label={label}
                                        key={res.id}
                                    />
                                )
                            case 'file':
                                return (
                                    <FormulaireDynamicFile
                                        formState={this.props.formState}
                                        res={res}
                                        resetState={this.props.resetState}
                                        required={required}
                                        disabled={disabled}
                                        handleChange={this.props.handleChange}
                                        label={label}
                                        key={res.id}
                                    />
                                )
                            case 'break_page':
                                ++page;
                                return (
                                    <div key={res.id} style={{margin:"20px auto", textAlign:"center",width:"40px", height:"40px", display:"block", borderRadius:"100px", lineHeight:"40px", border:"1px solid black"}}>
                                        {page}
                                    </div>
                                )
                            case 'Signature':
                                let name='v_' + res.id;
                                return (
                                    <Container key={res.id} style={{marginTop: "1rem", marginBottom: "0.5rem"}}>
                                        <Row>
                                            <span style={{color: this.props.formState['v_' + res.id+"error"] ? "red" : "rgba(0, 0, 0, 0.54)", marginLeft: "1rem", marginBottom: "0.5rem"}}>{label}</span>
                                        </Row>
                                        <Row>
                                            <Signature handleSignature={this.props.handleSignature} disabled={disabled} name={name} clearState={this.props.clearStateSignature} />
                                        </Row>
                                    </Container>
                                )
                            case 'address':
                                return (
                                    <FormulaireDynamicAddress
                                        formState={this.props.formState}
                                        res={res}
                                        error={error}
                                        required={required}
                                        disabled={disabled}
                                        handleChange={this.props.handleChange}
                                        key={res.id}
                                        inputProps={inputProps}
                                    />
                                )
                            case 'Position':
                                return "";
                            case 'nfc':
                            case 'qrcode':
                                return (
                                    <FormulaireDynamicTags
                                        formState={this.props.formState}
                                        res={res}
                                        error={error}
                                        label={label}
                                        required={required}
                                        disabled={disabled}
                                        handleChange={this.props.handleChange}
                                        key={res.id}
                                        handleTagTreatment={this.props.handleTagTreatment}
                                        inputProps={inputProps}
                                        type={res.type}
                                        resetState={this.props.resetState}
                                    />
                                )
                            default :
                                console.log("TYPE D'INPUT NON RECONNU : ", res)
                                return "";
                        }
                    } else {
                        return "";
                    }
                })}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    styles: state.style,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {},
        dispatch
    );

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(FormulaireDynamic);
